import React from 'react';
import ControlledEditor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import {Paper} from '@mui/material';
import {LoadingButton} from '@mui/lab';

export default function CustomStyles(props) {
	const model = props.model;
	
	return <>
		<Box mt={2}>
			<Paper>
				<Box pt={1} pb={1} className={'css-editor-container'}>
					<ControlledEditor
						theme={'active4d'}
						value={model.customCss || ''}
						onChange={v => props.onChange('customCss', v)}
						language={'css'}
						height={'50vh'}
						options={{minimap: {enabled: false}}}
					/>
					
					<LoadingButton variant={'contained'} onClick={props.onSubmit} loading={props.saving}>Save Changes</LoadingButton>
				</Box>
			</Paper>
		</Box>
	</>
}
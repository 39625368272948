import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import SettingsBox from '../../common/components/settings/SettingsBox';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import {Box} from '@mui/material';

export default function GeneralBox(props) {
	
	const model = props.model;
	const onChange = props.onChange;
	
	return <SettingsBox title={'General'}>
		<SettingsFormControl>
			<TextValidator
				fullWidth
				required
				variant='outlined'
				label='Name'
				validators={['required']}
				errorMessages={['Quiz name is required']}
				value={model.name}
				onChange={e => onChange('name', e.target.value)}
			/>
		</SettingsFormControl>
		
		<SettingsFormControl>
			<TextField required variant='outlined'
			           type='number'
			           InputProps={{
				           inputProps: {
					           min: 10
				           }
			           }}
			           label='Time per question'
			           helperText='Time in seconds'
			           value={model.questionTime}
			           onChange={e => onChange('questionTime', e.target.value)}
			/>
		</SettingsFormControl>
		
		<SettingsFormControl>
			<TextField required variant='outlined'
			           type='number'
			           InputProps={{
				           inputProps: {
					           min: 5
				           }
			           }}
			           label='Answer duration'
			           helperText='Time in seconds'
			           value={model.answerTime}
			           onChange={e => onChange('answerTime', e.target.value)}
			/>
		</SettingsFormControl>
		
		<SettingsFormControl>
			<TextField variant='outlined'
			           type='number'
			           InputProps={{
				           inputProps: {
					           min: 0,
					           max: 10
				           }
			           }}
			           label='Chat delay'
			           helperText='Time in seconds. Load chat messages with the delay. Use higher value for slow Internet connection'
			           value={model.chatDelay}
			           onChange={e => onChange('chatDelay', e.target.value)}
			/>
		</SettingsFormControl>
		
		<SettingsFormControl variant={'outlined'}>
			<InputLabel required style={{backgroundColor: '#fff'}}>Questions Order</InputLabel>
			<Select required value={model.questionOrder} onChange={e => onChange('questionOrder', e.target.value)}>
				<MenuItem value={'strict'}>In Order</MenuItem>
				<MenuItem value={'random'}>Random</MenuItem>
			</Select>
		</SettingsFormControl>
		
		<FormControlLabel
			control={<Switch color='primary' checked={model.loopQuestions} onChange={e => onChange('loopQuestions', e.target.checked)}/>}
			label='Loop Questions'
			labelPlacement='end'
		/>
		
		{model.loopQuestions && (
			<Box mt={2}>
				<SettingsFormControl>
					<TextField
						variant='outlined'
						type='number'
						InputProps={{
							inputProps: {
								min: 0
							}
						}}
						label='Questions per round'
						helperText={'Set 0 to ask all the questions'}
						value={model.questionsPerRound}
						onChange={e => onChange('questionsPerRound', e.target.value)}
					/>
				</SettingsFormControl>
			</Box>
		)}
	
	</SettingsBox>
}
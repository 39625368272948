import React from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import SettingsFormControl from '../../../common/components/settings/SettingsFormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FinalScreen from '../../../common-libs/quiz/screens/FinalScreen';
import MediaPicker from '../../../common/components/media/MediaPicker';
import FinalScreenSoundPicker from '../../components/FinalScreenSoundPicker';
import Scale from '../../../common/components/Scale';

export default function FinalStyleTabImage(props) {
	const model = props.model;
	
	return (
		<Grid container className={'preview-style-tab final-style-tab'} spacing={2}>
			<Grid size={3}>
				<Paper className={'side'}>
					
					<SettingsFormControl>
						<MediaPicker
							userId={model.user}
							media={model.screens.final.background}
							onSelect={media => props.onChange('screens.final.background', media)}
						/>
					</SettingsFormControl>
					
					<FinalScreenSoundPicker
						model={model}
						onChange={props.onChange}
					/>
					
					<Box className={'save'}>
						<Button
							variant='contained'
							color='primary'
							disabled={props.saving}
							onClick={props.onSubmit}
						>
							Save Changes
						</Button>
					</Box>
				</Paper>
			</Grid>
			
			<Grid size={9}>
				<Scale selector={'.quiz-screen'} className={'preview'}>
					<FinalScreen quiz={model}/>
				</Scale>
			</Grid>
		</Grid>
	)
}

import React from 'react';
import Header from '../components/Header';
import {Outlet} from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Container from '@mui/material/Container';

export default function AdminLayout(props) {
	return (
		<PrivateRoute outputType={'children'}>
			<div className={'admin-layout'}>
				<Header/>
				<Container className={'container'} maxWidth={'xl'}>
					{props.children}
					<Outlet/>
				</Container>
			</div>
		</PrivateRoute>
	);
}

import React from 'react';
import SettingsBox from '../../common/components/settings/SettingsBox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Box, TextField} from '@mui/material';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function ContactFormBox(props) {
	
	const model = props.model;
	const contactForm = model.contactForm || {};
	const contactFormSettings = model.contactFormSettings || {};
	
	const onChange = props.onChange;
	
	return (
		<SettingsBox title={'Contact Form'}>
			<Typography variant={'caption'}>Receive messages from verified YouTube users</Typography>
			<Box mb={3} mt={1}>
				<FormControlLabel
					control={<Switch color='primary' checked={contactFormSettings.enabled} onChange={e => onChange('contactFormSettings.enabled', e.target.checked)}/>}
					label='Enable Contact Form'
					labelPlacement='end'
				/>
			</Box>
			
			{contactFormSettings.enabled && <>
				<SettingsFormControl variant={'outlined'}>
					<InputLabel required style={{backgroundColor: '#fff'}}>Who can submit the form?</InputLabel>
					<Select
						required
						value={contactFormSettings.senderType || 'anyone'}
						onChange={e => onChange('contactFormSettings.senderType', e.target.value)}
					>
						<MenuItem key={`st1`} value='anyone'>Anyone</MenuItem>
						<MenuItem key={`st2`} value='player'>Players Only</MenuItem>
					</Select>
				</SettingsFormControl>
				
				<SettingsFormControl variant={'outlined'}>
					<TextField
						multiline={true}
						minRows={2}
						label={'Description'}
						helperText='Provide users instructions of what information they should submit'
						value={contactForm.description || ''}
						onChange={e => onChange('contactForm.description', e.target.value)}
					/>
				</SettingsFormControl>
				
				{/*<Box mb={2}>*/}
				{/*	<FormControlLabel*/}
				{/*		control={<Switch color='primary' checked={contactForm.notify} onChange={e => onChange('contactForm.notify', e.target.checked)}/>}*/}
				{/*		label='Notify via Email'*/}
				{/*		labelPlacement='end'*/}
				{/*	/>*/}
				{/*</Box>*/}
			</>}
		</SettingsBox>
	)
}
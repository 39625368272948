import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AuthRoutes from './auth/AuthRoutes';
import authService from './auth/AuthService';
import AdminLayout from './common/layouts/AdminLayout';
import UserContext from './common/UserContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {indigo, pink} from '@mui/material/colors';
import {CssBaseline} from '@mui/material';
import UserRoutes from './user/UserRoutes';
import AccountRoutes from './account/AccountRoutes';
import ChannelRoutes from './channel/ChannelRoutes';
import YoutubeRoutes from './youtube/YoutubeRoutes';
import TwitchRoutes from './twitch/TwitchRoutes';
import QuizRoutes from './quiz/QuizRoutes';
import PollRoutes from './poll/PollRoutes';
import BroadcastRoutes from './broadcast/BroadcastRoutes';
import Dashboard from './dashboard/Dashboard';

const theme = createTheme({
	palette: {
		primary: indigo,
		secondary: pink,
		success: {
			main: '#4caf50'
		},
		background: '#fafafa'
	}
});

export default class App extends React.Component {
	
	constructor(props, context) {
		super(props, context);
		this.state = {
			user: null,
			loading: true
		}
	}
	
	componentDidMount() {
		if (authService.getCurrentUser()) {
			authService
				.verifySession()
				.then(() => this.setState({
					loading: false,
					user: authService.getCurrentUser()
				}))
				.catch(e => console.log(e));
		} else {
			this.setState({
				loading: false
			});
		}
	}
	
	setUser(user) {
		this.setState({user: user});
	}
	
	render() {
		return <ThemeProvider theme={theme}>
			<CssBaseline/>
			{this.state.loading
				? <Backdrop open={true}><CircularProgress color='inherit'/></Backdrop>
				: <UserContext.Provider value={[this.state.user, u => this.setUser(u)]}>
					<BrowserRouter>
						<Routes>
							<Route path='/auth/*' element={<AuthRoutes/>}/>
							<Route path={'/'} element={<AdminLayout/>}>
								<Route path={'/user/*'} element={<UserRoutes/>}/>
								<Route path={'/account/*'} element={<AccountRoutes/>}/>
								<Route path={'/youtube/*'} element={<YoutubeRoutes/>}/>
								<Route path={'/twitch/*'} element={<TwitchRoutes/>}/>
								<Route path={'/channel/*'} element={<ChannelRoutes/>}/>
								<Route path={'/quiz/*'} element={<QuizRoutes/>}/>
								<Route path={'/poll/*'} element={<PollRoutes/>}/>
								<Route path={'/broadcast/*'} element={<BroadcastRoutes/>}/>
								<Route index element={<Dashboard/>}/>
							</Route>
						</Routes>
					</BrowserRouter>
				</UserContext.Provider>
			}
		</ThemeProvider>;
	}
}

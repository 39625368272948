import React from 'react';
import api from '../../common/ApiRequest';
import PagePreloader from '../../common/components/PagePreloader';

export default class YoutubeConnect extends React.Component {
	
	constructor(props, context) {
		super(props, context);
		this.state = {
			error: null
		};
	}
	
	componentDidMount() {
		api.get('/youtube/auth-url')
			.then(response => {
				window.location.href = response.url
			})
			.catch(e => this.setState({error: e.toString()}));
	}
	
	render() {
		return <PagePreloader error={this.state.error}/>
	}
}
import {useSearchParams} from 'react-router-dom';
import {useMemo} from 'react';

export function useQueryParams() {
	const [searchParams, setSearchParams] = useSearchParams();
	return useMemo(() => {
		let data = Object.fromEntries([...searchParams]);
		return [
			data,
			function (param, val) {
				const newSearchParams = {...data};
				newSearchParams[param] = val;
				if (param !== 'page') {
					newSearchParams.page = 1;
				}
				if (+newSearchParams.page === 1) {
					delete newSearchParams.page;
				}
				data = newSearchParams;
				setSearchParams(data);
			}
		];
	}, [searchParams, setSearchParams]);
}

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import api from '../common/ApiRequest';
import PagePreloader from '../common/components/PagePreloader';
import Notification from '../common/components/Notification';
import MainContainer from '../common/components/MainContainer';
import Grid from '@mui/material/Grid2';
import {Box, Paper, Typography} from '@mui/material';
import Status from './components/Status';
import {useNavigate, useParams} from 'react-router-dom';
import ClipboardButton from '../common/buttons/ClipboardButton';
import StartStopButton from './buttons/StartStopButton';
import Scale from '../common/components/Scale';
import StreamLink from '../common/components/StreamLink';
import ScoresButton from './buttons/ScoresButton';
import io from 'socket.io-client';
import PauseResumeButton from './buttons/PauseResumeButton';
import QuizTabs from './components/QuizTabs';
import AppBar from '@mui/material/AppBar';
import ToggleScoresScreenButton from './buttons/ToggleScoresScreenButton';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AjaxButton from '../common/buttons/AjaxButton';
import ActivityLogButton from './buttons/ActivityLogButton';
import ActivityLog from './components/ActivityLog';
import ScreenProgress from './components/ScreenProgress';

function ControlRoom(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [message, setMessage] = useState(null);
	const [model, setModel] = useState(props.model);
	const [loading, setLoading] = useState(false);
	const [starting, setStarting] = useState(false);
	const [iframeV, setIframeV] = useState(1);
	const [activityVisible, setActivityVisible] = useState(true);
	const [screenState, setScreenState] = useState(null);
	const [previewHeight, setPreviewHeight] = useState(720);
	const [scoreScreenType, setScoreScreenType] = useState(null);
	const socketRef = useRef(null);
	
	const totalScores = useMemo(() => model?.leaderboard?.total || 0, [model]);
	const isRunning = useMemo(() => !!model?.serverUrl && ['running', 'paused'].includes(model.status), [model]);
	
	const onStatusChange = type => {
		if (type === 'start') {
			setStarting(true);
		}
		setLoading(true);
	}
	
	const onError = useCallback(e => setMessage(e.toString()), [setMessage]);
	
	useEffect(() => {
		if (!socketRef.current && model.serverUrl) {
			socketRef.current = io(`${model.serverUrl}/?quizId=${model._id}&activity=1&leaderboard=1`, {transports: ['websocket']});
			
			socketRef.current.on('cmd', cmd => {
				if (cmd.action === 'screen') {
					setLoading(true);
					setScreenState(cmd);
					if (cmd.scoreScreenType) {
						setScoreScreenType(cmd.scoreScreenType);
					}
				}
			});
			socketRef.current.on('leaderboard:change', () => setTimeout(() => setLoading(true), 250));
			socketRef.current.on('game:end', () => setTimeout(() => setLoading(true), 250));
			socketRef.current.on('disconnect', () => setLoading(true));
		}
		
		return () => {
			if (socketRef.current) {
				socketRef.current.removeAllListeners()
				socketRef.current.disconnect();
			}
		}
	}, [model._id, model.serverUrl]);
	
	/**
	 * Wait till quiz is started.
	 */
	useEffect(() => {
		if (starting) {
			const interval = setInterval(() => setLoading(true), 2000);
			return () => clearInterval(interval);
		}
	}, [starting]);
	
	/**
	 * Connect to the game server when quiz is started.
	 */
	useEffect(() => {
		if (isRunning) {
			setStarting(false);
			setIframeV(prev => prev + 1);
		} else {
			setScreenState(null);
		}
	}, [isRunning]);
	
	/**
	 * Reload quiz data on demand.
	 */
	useEffect(() => {
		if (loading) {
			(async () => {
				try {
					const quiz = await api.get(`/apps/quiz/${params.quizId}?scores=1`);
					setModel(quiz);
					setStarting(quiz.status === 'starting');
					setScoreScreenType(prev => prev || quiz.defaultScoreScreen);
					
					// Try to reconnect if was disconnected.
					if (socketRef.current && !socketRef.current.connected) {
						socketRef.current.connect();
					}
				} catch (e) {
					setMessage(e.toString());
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [loading, params.quizId]);
	
	return (
		<div className={'app-control-room quiz'}>
			
			<Notification
				message={message}
				severity={'error'}
				onClear={() => setMessage(null)}
			/>
			
			<MainContainer maxWidth={activityVisible ? 'xl' : 'lg'}>
				<AppBar position={'sticky'} color='default' className={'app-bar'}>
					<QuizTabs onClick={path => navigate(`/quiz/${params.quizId}/${path}`)} selected={'control-room'} mode={'edit'}/>
				</AppBar>
				
				<Grid container spacing={2}>
					<Grid size={activityVisible ? 2 : 3}>
						<Paper className={'sidebar'}>
							<Box mb={2.5}>
								<Status model={model}/>
							</Box>
							
							<Typography variant='subtitle2' color={'secondary'} align={'center'} mb={1.5}>{model.name}</Typography>
							
							<Typography variant='subtitle2' color={'primary'} className={`title icon-title`}>
								Source URL
								<ClipboardButton value={model.clientUrl}/>
							</Typography>
							
							<Box mb={2.5}>
								<a href={model.clientUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
									<Typography variant='body2'>{model.clientUrl}</Typography>
								</a>
							</Box>
						</Paper>
						
						{(model.livestreams && model.livestreams.length > 0) && (
							<Paper className={'sidebar'}>
								<Typography variant='subtitle2' color={'primary'} className={`title icon-title`} mb={1.5}>
									Live Streams
								</Typography>
								{model.livestreams && model.livestreams.map((livestream, i) => <div key={`ls-${i}`}>
									<Box mb={1}>
										<StreamLink livestream={livestream}/>
									</Box>
								</div>)}
							</Paper>
						)}
						
						<Paper className={'sidebar'}>
							<Typography variant='subtitle2' color={'primary'} className={`title icon-title`}>
								Leaderboard URL
								<ClipboardButton value={model.leaderboardUrl}/>
							</Typography>
							
							<Box mb={2.5}>
								<a href={model.leaderboardUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
									<Typography variant='body2'>{model.leaderboardUrl}</Typography>
								</a>
							</Box>
							
							{model.teamsLeaderboardUrl && <>
								<Typography variant='subtitle2' color={'primary'} className={`title icon-title`}>
									Team Leaderboard URL
									<ClipboardButton value={model.teamsLeaderboardUrl}/>
								</Typography>
								
								<Box mb={2.5}>
									<a href={model.teamsLeaderboardUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
										<Typography variant='body2'>{model.teamsLeaderboardUrl}</Typography>
									</a>
								</Box>
							</>}
							
							{model.teamPlayersUrl && <>
								<Typography variant='subtitle2' color={'primary'} className={`title icon-title`}>
									Team Players URL
									<ClipboardButton value={model.teamPlayersUrl}/>
								</Typography>
								
								<Box mb={2.5}>
									<a href={model.teamPlayersUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
										<Typography variant='body2'>{model.teamPlayersUrl}</Typography>
									</a>
								</Box>
							</>}
							
							{model.contactFormUrl && <>
								<Typography variant='subtitle2' color={'primary'} className={`title icon-title`}>
									Contact Form URL
									<ClipboardButton value={model.contactFormUrl}/>
								</Typography>
								
								<Box mb={2.5}>
									<a href={model.contactFormUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
										<Typography variant='body2'>{model.contactFormUrl}</Typography>
									</a>
								</Box>
							</>}
							
							<Typography variant='subtitle2' color={'primary'} className={'title'}>Question Progress</Typography>
							<Box mb={2.5}>
								<Typography variant='body2'>
									{model.numQuestionsAsked ? model.numQuestionsAsked : 0} / {(model.loopQuestions && model.questionsPerRound > 0) ? model.questionsPerRound : model.numQuestions}
								</Typography>
							</Box>
							
							<Typography variant='subtitle2' color={'primary'} className={`title icon-title`}>
								Participants
								{totalScores > 0 && <ScoresButton app={model}/>}
							</Typography>
							<Box mb={2.5}>
								<Typography variant='body2'>{totalScores}</Typography>
							</Box>
						</Paper>
					</Grid>
					
					<Grid size={activityVisible ? 6 : 9}>
						<Paper className={'paper'}>
							
							<AjaxButton
								color={'primary'}
								progressColor={'primary'}
								exec={() => api.post(`/apps/quiz/${model._id}/cmd`, {cmd: 'next'})}
								icon={SkipNextIcon}
								title={'Skip to next'}
								disabled={!isRunning}
								onChange={onStatusChange}
								onError={e => setMessage(e.toString())}
							/>
							
							{(model.teams && model.teams.length > 0) && <ToggleScoresScreenButton
								quizId={model._id}
								currentScreen={scoreScreenType}
								disabled={!isRunning || !screenState || screenState.screen !== 'scores'}
								onError={e => setMessage(e.toString())}
							/>}
							
							<PauseResumeButton
								app={model}
								onError={e => setMessage(e.toString())}
								onChange={onStatusChange}
								disabled={!isRunning}
							/>
							
							<StartStopButton
								app={model}
								onError={e => setMessage(e.toString())}
								onChange={onStatusChange}
							/>
							
							<ActivityLogButton
								active={activityVisible}
								onClick={() => setActivityVisible(prev => !prev)}
							/>
						</Paper>
						
						<Box mt={2}>
							<Box style={{height: '4px'}}>
								{(screenState && screenState.running && screenState.till) && <ScreenProgress
									key={screenState ? screenState.screen : 'screenProgress'}
									startTime={screenState.screenDate || screenState.date}
									endTime={screenState.till}
								/>}
							</Box>
							
							<Box style={{maxHeight: `${previewHeight}px`, overflow: 'hidden'}}>
								<Scale selector={'iframe'} className={'preview'} onScale={scale => setPreviewHeight(720 * scale)}>
									<iframe
										title={'Quiz View'}
										className={`iframe`}
										src={`${model.clientUrl}?v=${iframeV}`}
									></iframe>
								</Scale>
							</Box>
							<Typography variant='caption' style={{textAlign: 'center'}} component={'div'}>If video or audio is not playing - click anywhere in the quiz
								preview</Typography>
						
						</Box>
					</Grid>
					
					{activityVisible && <Grid size={4}>
						<ActivityLog
							quizId={model._id}
							socket={socketRef.current}
							onError={onError}
						/>
					</Grid>}
				</Grid>
			</MainContainer>
		</div>);
}

export default function PageLoader() {
	
	const params = useParams();
	const [model, setModel] = useState(null);
	const [error, setError] = useState(null);
	
	useEffect(() => {
		let active = true;
		(async () => {
			try {
				const model = await api.get(`/apps/quiz/${params.quizId}?scores=1`);
				active && setModel(model);
			} catch (e) {
				active && setError(e.toString());
			}
		})();
		
		return () => active = false;
	}, [params.quizId]);
	
	return model ?
		<ControlRoom model={model}/> :
		<PagePreloader error={error}/>;
}

import React from 'react';
import '../styles.scss';
import CountDown from '../../components/CountDown';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';

/** @jsxImportSource @emotion/react */

export default function WelcomeScreen(props) {
	const model = props.quiz;
	const screen = model.screens.welcome;
	const timeLeft = props.timeLeft;
	
	return <Screen
		className={`welcome ${screen.countdown.position}`}
		bgColor={screen.bgColor}
		mediaBg={screen.background}
		css={{
			'& .countdown': {
				color: objectPath.get(model, 'screens.welcome.countdown.color'),
				backgroundColor: objectPath.get(model, 'screens.welcome.countdown.bgColor'),
				fontSize: `${objectPath.get(model, 'screens.welcome.countdown.fontSize')}px`
			}
		}}
	>
		{(model.status === 'running' && model.screens.welcome.countdown.enabled) && <CountDown key={timeLeft} timeLeft={timeLeft}/>}
	</Screen>
}

WelcomeScreen.propTypes = {
	quiz: PropTypes.object.isRequired,
	timeLeft: PropTypes.number.isRequired
};

import React, {useEffect, useRef, useState} from 'react';
import {IconButton} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopIcon from '@mui/icons-material/Stop';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export default function SoundPicker({label = 'Sound Effect', ...props}) {
	
	const audioRef = useRef(new Audio());
	const [open, setOpen] = useState(false)
	const sounds = props.sounds;
	const [playing, setPlaying] = useState(false);
	
	useEffect(() => {
		const audio = audioRef.current;
		const onStop = () => {
			setPlaying(false);
		}
		audio.addEventListener('pause', onStop);
		
		return () => {
			try {
				audio.removeEventListener('pause', onStop);
				audio.pause();
				audio.src = '';
				audio.load();
			} catch (e) {
				console.log(e);
			}
		}
	}, []);
	
	const onClose = async () => {
		setOpen(false);
		await stopSound();
	}
	
	const playSound = async s => {
		try {
			const audio = audioRef.current;
			s = s || props.value;
			audio.pause();
			audio.src = `${process.env.REACT_APP_S3_BUCKET}/sounds/${s}.mp3`;
			audio
				.play()
				.then(() => setPlaying(true))
				.catch(e => console.log(e));
		} catch (e) {
			console.log(e);
		}
	};
	
	const stopSound = async () => {
		try {
			audioRef.current.pause();
			setPlaying(false);
		} catch (e) {
			console.log(e);
		}
	}
	
	const togglePlay = () => {
		playing ? stopSound() : playSound();
	}
	
	const sound = props.value || '';
	
	return (
		<div className={'sound-picker'}>
			<Box className={'dropdown'}>
				<InputLabel style={{backgroundColor: '#fff'}} shrink>{label}</InputLabel>
				<Select
					displayEmpty
					className={'select'}
					value={sound}
					onChange={e => props.onChange(e.target.value)}
					onClose={onClose}
					onOpen={() => setOpen(true)}
				>
					<MenuItem value='' onMouseOver={stopSound}>None</MenuItem>
					{Object.keys(sounds).map(id => <MenuItem
							value={id}
							key={id}
							onMouseOver={() => open && playSound(id)}
						>{sounds[id]}</MenuItem>
					)}
				</Select>
			</Box>
			
			<IconButton
				disabled={!sound || !sound.length}
				onClick={() => togglePlay()}
				size='large'>
				{playing ?
					<StopIcon/> :
					<PlayCircleOutlineIcon/>
					
				}
			</IconButton>
		</div>
	);
}

SoundPicker.propTypes = {
	label: PropTypes.string,
	sounds: PropTypes.object,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired
}

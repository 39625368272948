import React from 'react';
import IconButton from '@mui/material/IconButton';
import {Link} from 'react-router-dom';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import {Tooltip} from '@mui/material';
import PropTypes from 'prop-types';

export default function ControlRoomButton(props) {
	return (
		<Tooltip title={'Control Room'}>
			<span>
				<IconButton
					aria-label='control-room'
					color='secondary'
					component={Link}
					to={`/quiz/${props.app._id}/control-room`}
					size='small'
				>
					<SettingsRemoteIcon fontSize={'small'}/>
				</IconButton>
				</span>
		</Tooltip>
	);
}

ControlRoomButton.propTypes = {
	app: PropTypes.object.isRequired
};
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import TwitchConnect from './pages/TwitchConnect';
import TwitchCallback from './pages/TwitchCallback';

export default function TwitchRoutes() {
	return (
		<Routes>
			<Route path={`connect`} element={<TwitchConnect/>}/>
			<Route path={`callback`} element={<TwitchCallback/>}/>
		</Routes>
	)
}
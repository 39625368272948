/**
 * @param livestream
 * @return {string|*|null}
 */
export function getLiveStreamURL(livestream) {
	if (!livestream) {
		return null;
	}
	
	const channel = livestream.channel;
	if (!channel) {
		return null;
	}
	
	if (channel.socialType === 'youtube') {
		return `https://youtube.com/watch?v=${livestream.ytStreamId}`;
	} else if (channel.socialType === 'twitch') {
		return channel.url;
	}
	
	return null;
}

/**
 * @param livestream
 * @param channels
 * @return {*|null}
 */
export function getLiveStreamChannelType(livestream, channels = null) {
	if (!livestream || !livestream.channel) {
		return null;
	} else if (typeof livestream.channel === 'object') {
		return livestream.channel.socialType;
	}
	
	if (channels) {
		const channel = channels.find(c => c._id === livestream.channel);
		if (channel) {
			return channel.socialType;
		}
	}
	
	return null;
}

import React, {useEffect, useState} from 'react';
import api from '../../common/ApiRequest';
import PagePreloader from '../../common/components/PagePreloader';
import {useParams, useLocation} from 'react-router-dom';
import UserForm from '../components/UserForm';
import Notification from '../../common/components/Notification';

export default function EditUser() {
	
	const params = useParams();
	const location = useLocation();
	
	const userId = params.userId;
	const created = new URLSearchParams(location.search).get('created');
	const [data, setData] = useState({});
	const [pageLoading, setPageLoading] = useState(true);
	const [pageError, setPageError] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(created ? 'User created' : null);
	
	/**
	 * Load user data.
	 */
	useEffect(() => {
		(async () => {
			try {
				const user = await api.get('/user/' + userId);
				user.password = '';
				user.password2 = '';
				setData(user);
				setPageLoading(() => false);
			} catch (e) {
				setPageError(e.toString());
			}
		})();
	}, [userId]);
	
	/**
	 * Submit form data.
	 */
	const handleSubmit = data => {
		setLoading(true);
		setError(() => null);
		setSuccess(() => null);
		
		api.post(`/user/${params.userId}`, data)
			.then(user => {
				user.password = '';
				user.password2 = '';
				setData(() => user);
				setSuccess(() => 'All Changes Saved');
			})
			.catch(e => setError(() => e.toString()))
			.finally(() => setLoading(() => false));
	};
	
	// Initial load.
	if (pageLoading || pageError) {
		return <PagePreloader error={pageError}/>
	}
	
	// User form.
	return (
		<>
			<UserForm
				data={data}
				goBackLevel={created? -2: -1}
				loading={loading}
				onSubmit={handleSubmit}
				title={'Edit User'}
			/>
			{success && <Notification message={success} onClear={() => setSuccess(null)} horizontalPos={'left'}/>}
			{error && <Notification message={error} onClear={() => setError(null)} severity={'error'} horizontalPos={'left'}/>}
		</>
	);
}
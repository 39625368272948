import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress, DialogActions, DialogContent, ImageList, ImageListItem, ImageListItemBar} from '@mui/material';
import UploadButton from '../../buttons/UploadButton';
import api from '../../ApiRequest';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HumanTime from '../../../common-libs/components/HumanTime';
import SyncIcon from '@mui/icons-material/Sync';
import IconButton from '@mui/material/IconButton';
import sleep from '../../../common-libs/utils/sleep';
import auth from '../../../auth/AuthService';
import objectPath from 'object-path';

export default function VideoDialogBody({userId, ...props}) {
	const user = auth.getCurrentUser();
	const videoMedia = (user.role === 'admin' || !!objectPath.get(user, 'permissions.video'));
	
	const [uploading, setUploading] = useState(false);
	const [selected, setSelected] = useState(props.selected);
	const [search, setSearch] = useState({
		page: 1,
		loading: videoMedia,
		items: [],
		totalPages: 1
	});
	
	/**
	 * Load videos.
	 */
	useEffect(() => {
		if (!search.loading || !videoMedia) {
			return;
		}
		
		(async () => {
			await sleep(500);
			api.get('/video/', {userId, page: search.page})
				.then(result => {
					const prevItems = search.page === 1 ? [] : search.items;
					setSearch({
						items: [...prevItems, ...result.data],
						page: result.pagination.page,
						totalPages: result.pagination.totalPages,
						loading: false
					});
				})
				.catch(e => {
					console.log(e);
					setSearch(prev => ({
						...prev,
						loading: false
					}));
				});
		})();
		
	}, [search, userId, videoMedia]);
	
	const loadMore = () => {
		setSearch(prev => ({...prev, page: prev.page + 1, loading: true}));
	}
	
	const onClose = () => {
		props.onClose && props.onClose();
	}
	
	const onError = e => {
		props.onError && props.onError(e);
		setUploading(() => false);
	}
	
	const onRefreshClick = async () => {
		refresh();
	}
	
	const onSelect = item => {
		props.onSelect && props.onSelect(item);
	}
	
	const onStart = () => {
		setUploading(() => true);
	}
	
	const onUpload = () => {
		setUploading(() => false);
		refresh();
	}
	
	const refresh = () => {
		setSearch(prev => ({
			...prev,
			page: 1,
			loading: true
		}));
	}
	
	return (
		<>
			<DialogContent className={'video-dialog-content'} dividers>
				<ImageList cols={4} className={'image-list'}>
					{search.items.map((video, i) => <ImageListItem
							key={`${video._id}`}
							onClick={() => video.status === 'ready' && setSelected(video)}
							className={'image-list-item'}
						>
							<img
								alt={''}
								src={video.status === 'ready' ? video.thumbnails.small : '/images/film.webp'}
								loading={'lazy'}
							/>
							<ImageListItemBar
								className={`image-bar ${(selected && selected._id === video._id) ? 'selected' : ''}`}
								actionIcon={video.status === 'ready' ? <PlayArrowIcon style={{color: '#fff'}} onClick={() => window.open(video.src)}/> : null}
								title={video.name}
								subtitle={video.status === 'ready' ? <HumanTime time={video.duration}/> : 'Processing...'}
							/>
						</ImageListItem>
					)}
				</ImageList>
				
				{(videoMedia && search.totalPages > search.page) && <Button className={'load-more-btn'} onClick={loadMore} disabled={search.loading}>Load More</Button>}
			</DialogContent>
			
			<DialogActions className={'video-dialog-actions'}>
				<UploadButton
					onStart={onStart}
					onComplete={onUpload}
					onError={onError}
					title={'Upload Video'}
					className={'upload-btn'}
					uploadData={{userId}}
					accept={'video/mp4,video/webm'}
					maxSize={+process.env.REACT_APP_VIDEO_MAX_SIZE}
					uploadUrl={'/video/upload'}
					disabled={!videoMedia}
				/>
				
				<IconButton onClick={onRefreshClick} disabled={search.loading || !videoMedia} className={'refresh-btn'} size={'small'}>
					{search.loading && <CircularProgress size={20} className={'progress'}/>}
					{!search.loading && <SyncIcon color={'primary'}/>}
				</IconButton>
				
				<Button color={'secondary'} variant={'contained'} disabled={uploading} onClick={onClose}>Cancel</Button>
				<Button color={'primary'} variant={'contained'} disabled={!selected || uploading || !videoMedia} onClick={() => onSelect(selected)}>Select this video</Button>
			</DialogActions>
		</>
	)
}

VideoDialogBody.propTypes = {
	onClose: PropTypes.func,
	onError: PropTypes.func,
	onSelect: PropTypes.func,
	userId: PropTypes.string.isRequired
}

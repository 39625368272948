import React from 'react';
import PropTypes from 'prop-types';
import api from '../../common/ApiRequest';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import AjaxButton from '../../common/buttons/AjaxButton';
import PauseIcon from '@mui/icons-material/Pause';

export default function PauseResumeButton(props) {
	const app = props.app;
	const status = app.status;
	
	return <AjaxButton
		ariaLabel={status}
		color={status === 'paused' ? 'success' : 'primary'}
		progressColor={status === 'paused' ? 'primary' : 'success'}
		exec={() => api.post(`/apps/quiz/${app._id}/cmd`, {cmd: status === 'paused' ? 'resume' : 'pause'})}
		icon={status === 'running' ? PauseIcon : NotStartedIcon}
		{...props}
	/>
}

PauseResumeButton.propTypes = {
	app: PropTypes.object.isRequired,
	onError: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
}
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {arrayMoveMutable} from 'array-move';
import api from '../../common/ApiRequest';
import {DndContext, closestCenter, PointerSensor, useSensor, useSensors,} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy,} from '@dnd-kit/sortable';
import {restrictToVerticalAxis, restrictToParentElement,} from '@dnd-kit/modifiers';
import SortableItem from './SortableItem';

export default function QuestionList(props) {
	
	const questions = props.questions;
	const [version, setVersion] = useState(1);
	const sensors = useSensors(useSensor(PointerSensor));
	
	/**
	 * @param event
	 */
	const handleDrop = async (event) => {
		
		const {active, over} = event;
		if (over && active.id !== over.id) {
			const oldIndex = questions.findIndex(q => q._id === active.id);
			const newIndex = questions.findIndex(q => q._id === over.id);
			
			const q1 = questions[oldIndex];
			const q2 = questions[newIndex];
			
			arrayMoveMutable(questions, oldIndex, newIndex);
			setVersion(version + 1);
			
			await updatePosition(q1._id, q2.position);
		}
	}
	
	/**
	 * @param index
	 */
	const handleUp = async index => {
		if (index === 0) {
			const q = questions[index];
			if (q.position > 1) {
				await updatePosition(q._id, q.position - 1);
			}
			return;
		}
		await swapQuestions(index, index - 1);
	}
	
	/**
	 * @param index
	 */
	const handleDown = async index => {
		if (index >= questions.length - 1) {
			const q = questions[index];
			await updatePosition(q._id, q.position + 1);
			return;
		}
		await swapQuestions(index, index + 1);
	}
	
	/**
	 * @param i
	 */
	const onRemove = i => {
		const q = questions[i];
		questions.splice(i, 1);
		props.onRemove && props.onRemove(q._id)
	};
	
	/**
	 * @param index1
	 * @param index2
	 */
	const swapQuestions = async (index1, index2) => {
		setVersion(version + 1);
		
		const q1 = questions[index1];
		const q2 = questions[index2];
		
		questions[index1] = questions[index2];
		questions[index2] = q1;
		
		await updatePosition(q1._id, q2.position);
	}
	
	/**
	 * @param questionId
	 * @param position
	 * @returns {Promise<void>}
	 */
	const updatePosition = async (questionId, position) => {
		try {
			await api.post(`/apps/quiz/question/${questionId}/position`, {position});
			props.onChange && props.onChange();
		} catch (e) {
			props.onError && props.onError(e.toString());
		}
	}
	
	return <div key={`list${version}`}>
		<DndContext
			sensors={sensors}
			modifiers={[restrictToVerticalAxis, restrictToParentElement]}
			collisionDetection={closestCenter}
			onDragEnd={handleDrop}
		>
			<SortableContext
				items={questions.map(q => q._id)}
				strategy={verticalListSortingStrategy}
			>
				{questions.map((q, i) => <SortableItem
					key={q._id}
					id={q._id}
					question={q}
					onLoad={q => props.onLoad && props.onLoad(q)}
					onRemove={() => onRemove(i)}
					onMoveUp={() => handleUp(i)}
					onMoveDown={() => handleDown(i)}
				/>)}
			</SortableContext>
		</DndContext>
	</div>
}

QuestionList.propTypes = {
	questions: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	onError: PropTypes.func,
	onLoad: PropTypes.func,
	onRemove: PropTypes.func
}
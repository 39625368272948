import React from 'react';
import SoundPicker from '../../common/components/settings/SoundPicker';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import PropTypes from 'prop-types';

const sounds = {
	'music/8th-world-wonder': '8th World Wonder',
	'music/all-the-fixings': 'All The Fixings',
	'music/chocolate-cookie-jam': 'Chocolate Cookie Jam',
	'music/dorris-day': 'Dorris Day',
	'music/end-of-the-rainbow': 'End of The Rainbow',
	'music/fast-and-run': 'Fast and Run',
	'music/fun-activity-montage': 'Fun Activity Montage',
	'music/happy-slaps-back': 'Happy Slaps Back',
	'music/headphones': 'Headphones',
	'music/higher-kiss': 'Higher Kiss',
	'music/limo-ride': 'Limo Ride',
	'music/love-aside': 'Love Aside',
	'music/meet-n-fun': 'Meet & Fun!',
	'music/my-achy-heart': 'My Achy Heart',
	'music/night-run-away': 'Night Run Away',
	'music/one-more-time': 'One More Time',
	'music/snake-on-the-beach': 'Snake on the Beach',
	'music/swing-house': 'Swing House',
	'music/where-we-wanna-go': 'Where We Wanna Go',
	'music/yellow-rose-of-berkeley': 'Yellow Rose of Berkeley'
};

export default function FinalScreenSoundPicker(props) {
	return (
		<SettingsFormControl variant={'outlined'}>
			<SoundPicker
				sounds={sounds}
				value={props.model.screens.final.sound}
				onChange={value => props.onChange('screens.final.sound', value)}
			/>
		</SettingsFormControl>
	)
}

FinalScreenSoundPicker.propTypes = {
	model: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
}
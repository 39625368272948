import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

export default function AccountBox(props) {
	return (
		<Paper className={'account'}>
			<Container maxWidth='xs'>
				<div className={'paper'}>
					<Typography component='h1' variant='h5'>
						{props.title}
					</Typography>
					
					{props.children}
				</div>
			</Container>
		</Paper>
	);
}
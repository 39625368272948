import React, {useState} from 'react';
import api from '../../common/ApiRequest';
import UserForm from '../components/UserForm';
import Notification from '../../common/components/Notification';
import {useNavigate} from 'react-router-dom';

export default function AddUser() {
	
	const data = {
		name: '',
		email: '',
		role: 'creator',
		password: '',
		password2: '',
		active: true,
		permissions: {
			quiz: true,
			poll: true,
			broadcast: true,
			video: true
		}
	};
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	
	/**
	 * Submit form data.
	 */
	const handleSubmit = data => {
		setLoading(true);
		setError(() => '');
		
		api.post(`/user/`, data)
			.then(user => {
				navigate(`/user/${user._id}?created=1`);
			})
			.catch(e => {
				setError(() => e.toString());
				setLoading(() => false);
			});
	};
	
	return (
		<>
			<UserForm
				data={data}
				loading={loading}
				onSubmit={handleSubmit}
				passwordRequired={true}
				submitLabel={'Create User'}
				title={'Add User'}
			/>
			{error && <Notification message={error} onClear={() => setError(null)} severity={'error'} horizontalPos={'left'}/>}
		</>
	);
}
import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import SettingsFormControl from './SettingsFormControl';
import PropTypes from 'prop-types';

export default function SizePicker({label = 'Font Size', min = 50, max = 200, ...props}) {
	
	const onChange = e => {
		if (!props.onChange) {
			return;
		}
		
		let val = +e.target.value;
		if (val > max) {
			val = max;
		}
		
		props.onChange(val);
	}
	
	return (
		<SettingsFormControl>
			<TextValidator
				fullWidth
				variant='outlined'
				type='number'
				InputProps={{inputProps: {min, max}}}
				label={label}
				value={props.value}
				validators={[`minNumber:${min}`, `maxNumber:${max}`]}
				errorMessages={[`Minimum value is ${min}`, `Maximum value is ${max}`]}
				onChange={onChange}
			/>
		</SettingsFormControl>
	)
}

SizePicker.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func
}

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTwitch, faYoutube} from '@fortawesome/free-brands-svg-icons';

export default function SocialIcon({type, size = 'lg'}) {
	
	if (type === 'twitch') {
		return <FontAwesomeIcon icon={faTwitch} size={size} className={'social-icon color-twitch'} title={'Twitch'}/>;
	}
	
	if (type === 'youtube') {
		return <FontAwesomeIcon icon={faYoutube} size={size} className={'social-icon color-youtube'} title={'YouTube'}/>;
	}
	
	return <></>;
}

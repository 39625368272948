import React from 'react';
import SettingsBox from '../../common/components/settings/SettingsBox';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import {TextValidator} from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';

export default function GeneralBox(props) {
	const model = props.model;
	
	return (
		<SettingsBox title={'General'}>
			<SettingsFormControl>
				<TextValidator
					errorMessages={['Poll name is required']}
					fullWidth
					label='Name'
					onChange={e => props.onChange('name', e.target.value)}
					required
					validators={['required']}
					value={model.name}
					variant='outlined'
				/>
			</SettingsFormControl>
		</SettingsBox>
	)
}

GeneralBox.propTypes = {
	model: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
}
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import AccountProfile from './pages/AccountProfile';
import './style.scss';

export default function AccountRoutes() {
	return (
		<Routes>
			<Route index element={<AccountProfile/>}/>
		</Routes>
	)
}
import {Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel} from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function ConfirmDialog(props) {
	const [doNotShow, setDoNotShow] = useState(false);
	
	return <Dialog
		maxWidth='xs'
		aria-labelledby='confirmation-dialog-title'
		open={true}
		className={'confirm-dialog quiz'}
	>
		<DialogContent className={'padding'} dividers>
			<Alert severity={'error'}>The quiz is running now. Are you sure you want to save and apply changes?</Alert>
		</DialogContent>
		<DialogActions className={'padding'}>
			<FormControlLabel
				className={'checkbox'}
				control={<Checkbox checked={doNotShow} onChange={e => setDoNotShow(e.target.checked)}/>}
				label={<Typography className={'label'}>Don't ask me again</Typography>}
			/>
			<Button onClick={props.onCancel} color='secondary' variant={'contained'}>Cancel</Button>
			<Button onClick={() => props.onSubmit(doNotShow)} color='primary' variant={'contained'}>Save</Button>
		</DialogActions>
	</Dialog>
}

ConfirmDialog.propTypes = {
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}

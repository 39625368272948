import React, {useEffect, useState} from 'react';
import PollEditor from './components/PollEditor';
import api from '../common/ApiRequest';
import PagePreloader from '../common/components/PagePreloader';
import Notification from '../common/components/Notification';
import WithChannels from '../common/providers/WithChannels';
import UserIdContext from '../common/UserIdContext';
import {sanitize} from './PollService';
import {useLocation, useParams} from 'react-router-dom';

export default function PollEdit() {
	
	const params = useParams();
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	
	const [loadingError, setLoadingError] = useState(null);
	
	let msg = null;
	if (query.get('created')) {
		msg = 'Poll has been created';
	} else if (query.get('duplicated')) {
		msg = 'Poll has been duplicated';
	}
	const [message, setMessage] = useState(msg);
	
	const [model, setModel] = useState(null);
	const [saving, setSaving] = useState(false);
	const [severity, setSeverity] = useState('success');
	const [tab] = useState(query.get('created') ? 1 : 0);
	
	useEffect(() => {
		(async () => {
			try {
				const poll = await api.get(`/apps/poll/${params.pollId}`);
				setModel(poll);
			} catch (e) {
				setLoadingError(e.toString());
			}
		})();
	}, [params.pollId]);
	
	const onError = error => {
		setMessage(() => error);
		setSeverity(() => 'error');
	}
	
	const onSubmit = model => {
		setSaving(true);
		
		api.post(`/apps/poll/${model._id}`, sanitize(model))
			.then(model => {
					setModel(() => model);
					setMessage(() => 'Saved');
					setSeverity(() => 'success');
				}
			)
			.catch(e => {
				setMessage(() => e.toString());
				setSeverity(() => 'error');
			}).finally(() => setSaving(() => false));
	}
	
	if (loadingError || !model) {
		return <PagePreloader error={loadingError}/>
	}
	
	return (
		<UserIdContext.Provider value={[model.user]}>
			<Notification
				message={message}
				severity={severity}
				onClear={() => setMessage(null)}
			/>
			<WithChannels userId={model.user}>
				<PollEditor
					model={model}
					saving={saving}
					tab={tab}
					onSubmit={onSubmit}
					onError={error => onError(error.toString())}
				/>
			</WithChannels>
		</UserIdContext.Provider>
	);
}

import React, {useEffect, useMemo, useState} from 'react';
import {Tab} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import WelcomeStyleTab from './styles/WelcomeStyleTab';
import Paper from '@mui/material/Paper';
import QuestionStyleTab from './styles/QuestionStyleTab';
import AnswerStyleTab from './styles/AnswerStyleTab';
import ScoresStyleTab from './styles/ScoresStyleTab';
import FinalStyleTabImage from './styles/FinalStyleTabImage';
import FinalStyleTabScores from './styles/FinalStyleTabScores';
import CustomStyles from './styles/CustomStyles';
import WatermarkContext from '../../common-libs/context/WatermarkContext';
import {useLocation, useNavigate} from 'react-router-dom';

export default function StyleTab(props) {
	
	const navigate = useNavigate();
	const location = useLocation();
	const [currentTab, setCurrentTab] = useState(0);
	const model = props.model;
	const tabs = useMemo(() => {
		return [
			{title: 'Welcome Screen', component: WelcomeStyleTab, active: model.screens.welcome.enabled, path: 'welcome'},
			{title: 'Question', component: QuestionStyleTab, active: true, path: 'question'},
			{title: 'Answer', component: AnswerStyleTab, active: true, path: 'answer'},
			{title: 'Scores', component: ScoresStyleTab, active: true, path: 'scores'},
			{title: 'Final Screen', component: model.screens.final.screenType === 'scores' ? FinalStyleTabScores : FinalStyleTabImage, active: true, path: 'final'},
			{title: 'Custom Styles', component: CustomStyles, active: true, path: 'custom-css'},
		].filter(e => e.active);
	}, [model]);
	const CurrentComponent = tabs[currentTab].component;
	
	/**
	 * Update selected tab once location is changed.
	 */
	useEffect(() => {
		const index = tabs.findIndex(tab => tab.path && location.pathname.indexOf(tab.path) !== -1);
		index === -1 ? setCurrentTab(0) : setCurrentTab(index);
	}, [location, tabs]);
	
	const tabsEl = tabs.map((t, i) => <Tab
		label={t.title}
		key={`sub-tab-${i}`}
		id={`sub-tab-${i}`}
		aria-controls={`sub-tabpanel-${i}`}
		onClick={() => navigate(`/quiz/${model._id}/styles/${t.path}`)}
	/>);
	
	return (
		<>
			<Paper>
				<Tabs
					value={currentTab}
					indicatorColor='primary'
					textColor='primary'
					centered
				>
					{tabsEl}
				</Tabs>
			</Paper>
			
			<WatermarkContext.Provider value={false}>
				<style>
					{`.preview{${model.customCss}}`}
				</style>
				<CurrentComponent
					model={model}
					onChange={props.onChange}
					saving={props.saving}
					onSubmit={props.onSubmit}
				/>
			</WatermarkContext.Provider>
		</>
	)
}

import React, {useState} from 'react';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip} from '@mui/material';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function DeleteButton({apiUrl, onSuccess, onError, message, title = 'Delete'}) {
	
	const [dialogOpen, setDialogOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const classes = {};
	
	const execDelete = () => {
		setLoading(true);
		api
			.delete(apiUrl)
			.then(() => onSuccess && onSuccess())
			.catch(e => onError && onError(e))
			.finally(() => setLoading(false));
	};
	
	/**
	 * On stop confirmation.
	 *
	 * @param decision
	 */
	const onConfirm = decision => {
		if (decision) {
			execDelete();
		}
		setDialogOpen(false);
	}
	
	return <>
		<Dialog
			maxWidth='xs'
			aria-labelledby='confirmation-dialog-title'
			open={dialogOpen}
		>
			<DialogTitle>Confirm deletion</DialogTitle>
			<DialogContent className={classes.padding} dividers>
				{message}
			</DialogContent>
			<DialogActions className={classes.padding}>
				<Button onClick={() => onConfirm(false)} color='secondary' variant={'contained'}>Cancel</Button>
				<Button onClick={() => onConfirm(true)} color='primary' variant={'contained'}>Delete</Button>
			</DialogActions>
		</Dialog>
		
		{loading
			? <CircularProgress
				color='secondary'
				size={20}
				style={{display: 'flex', alignItems: 'center', margin: '0 0 0 10px'}}
			/>
			: <Tooltip title={title}>
				<IconButton aria-label='start' color='secondary' onClick={() => setDialogOpen(true)} size='small'>
					<DeleteIcon fontSize={'small'}/>
				</IconButton>
			</Tooltip>
		}
	</>;
}

DeleteButton.propTypes = {
	apiUrl: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	onError: PropTypes.func,
	onSuccess: PropTypes.func,
	title: PropTypes.string,
}

import React from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import AjaxButton from '../../common/buttons/AjaxButton';

export default function StartButton({title = 'Start', disabled = false, ...props}) {
	return (
		<AjaxButton
			ariaLabel={'start'}
			color={'secondary'}
			exec={() => api.post(`/apps/broadcast/${props.app._id}/start`)}
			icon={PlayArrowIcon}
			title={title}
			disabled={disabled}
			{...props}
		/>
	);
}

StartButton.propTypes = {
	app: PropTypes.object.isRequired,
	title: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onError: PropTypes.func
}

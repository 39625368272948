import React from 'react';
import PropTypes from 'prop-types';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import IconButton from '@mui/material/IconButton';
import {Tooltip} from '@mui/material';

export default function ActivityLogButton({active = true, disabled = false, onClick}) {
	return (
		<Tooltip title={'Toggle Activity Log'}>
			<IconButton size={'large'} onClick={onClick} disabled={disabled}>
				<MonitorHeartIcon fontSize={'large'} color={active ? 'primary' : ''}/>
			</IconButton>
		</Tooltip>
	)
}

ActivityLogButton.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
}

import React, {useEffect, useState} from 'react';
import QuestionForm from '../components/QuestionForm';
import MainContainer from '../../common/components/MainContainer';
import ActionBar from '../../quiz/components/ActionBar';
import {Button, useTheme} from '@mui/material';
import {arrayMoveMutable} from 'array-move';
import WatermarkContext from '../../common-libs/context/WatermarkContext';
import {v4 as uuidv4} from 'uuid';
import {closestCenter, DndContext, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {restrictToParentElement, restrictToVerticalAxis} from '@dnd-kit/modifiers';
import SortableItem from '../components/SortableItem';
import ImportQuestions from '../buttons/ImportQuestions'
import ExportQuestions from '../buttons/ExportQuestions';

export default function QuestionsTab(props) {
	
	const createEmptyQuestion = () => {
		return {
			id: uuidv4(),
			text: '',
			background: null,
			bgColor: '#fff',
			chartType: 'bars',
			chartPosition: 'right',
			counter: {
				display: true,
				vPosition: 'bottom',
				hPosition: 'left',
				fontSize: 50,
				color: '#fff',
				bgColor: '#000',
				borderSize: 0,
				borderColor: '#000',
				margin: 5,
				padding: 5,
			},
			options: [
				{text: 'Option 1', command: '', seriesColor: '#D41818', textColor: '#000'},
				{text: 'Option 2', command: '', seriesColor: '#3293ef', textColor: '#000'},
			]
		}
	};
	
	const theme = useTheme();
	const [questions, setQuestions] = useState([]);
	const [addingNew, setAddingNew] = useState(false);
	const [editingQuestion, setEditingQuestion] = useState(null);
	const sensors = useSensors(useSensor(PointerSensor));
	
	useEffect(() => {
		setQuestions(props.model.questions.map(q => {
			if (!q.id) {
				q.id = uuidv4();
			}
			return q;
		}));
	}, [props.model.questions]);
	
	const handleChange = (index, updatedQuestion) => {
		const newList = [...questions];
		newList[index] = updatedQuestion;
		props.onChange(newList);
	};
	
	const handleDragEnd = (event) => {
		const {active, over} = event;
		if (over && active.id !== over.id) {
			const newList = [...questions];
			const oldIndex = questions.findIndex(q => q.id === active.id);
			const newIndex = questions.findIndex(q => q.id === over.id);
			arrayMoveMutable(newList, oldIndex, newIndex);
			
			props.onChange(newList);
		}
	};
	
	const handleNewQuestion = newQuestion => {
		setAddingNew(false);
		const newList = [...questions];
		newList.push(newQuestion);
		props.onChange(newList);
	}
	
	const handleRemove = index => {
		const newList = [...questions];
		newList.splice(index, 1);
		props.onChange(newList);
	}
	
	const handleUp = index => {
		if (index === 0) {
			return;
		}
		swapQuestions(index, index - 1);
	}
	
	const handleDown = index => {
		if (index >= questions.length - 1) {
			return;
		}
		swapQuestions(index, index + 1);
	}
	
	const swapQuestions = (index1, index2) => {
		const newList = [...questions];
		const q1 = newList[index1];
		newList[index1] = newList[index2];
		newList[index2] = q1;
		props.onChange(newList);
	}
	
	return (
		<WatermarkContext.Provider value={false}>
			<ActionBar saving={props.saving} saveDisabled={!!(addingNew || editingQuestion)} onSubmit={props.onSubmit}>
				<ImportQuestions model={props.model} onComplete={newQuestions => props.onChange(newQuestions)}/>
				<ExportQuestions model={props.model}/>
				
				<Button
					variant={'contained'}
					color={'primary'}
					disabled={!!(addingNew || editingQuestion)}
					style={{marginRight: theme.spacing(1)}}
					onClick={() => setAddingNew(true)}
				>
					Add Question
				</Button>
			</ActionBar>
			
			<MainContainer>
				
				{(addingNew || editingQuestion) && <style>
					{`.preview{${props.model.customCss}}`}
				</style>}
				
				{addingNew && <QuestionForm
					question={createEmptyQuestion()}
					onCancel={() => setAddingNew(false)}
					onChange={q => handleNewQuestion(q)}
				/>}
				
				{editingQuestion && <QuestionForm
					question={editingQuestion[1]}
					onCancel={() => setEditingQuestion(null)}
					onChange={q => {
						handleChange(editingQuestion[0], q);
						setEditingQuestion(null);
					}}
				/>}
				
				{(!addingNew && !editingQuestion) && <DndContext
					sensors={sensors}
					modifiers={[restrictToVerticalAxis, restrictToParentElement]}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<SortableContext
						items={questions.map(q => q.id)}
						strategy={verticalListSortingStrategy}
					>
						{questions.map((q, i) => <SortableItem
							key={q.id}
							question={q}
							position={i}
							canMoveUp={i > 0}
							canMoveDown={i < questions.length - 1}
							onEdit={() => setEditingQuestion([i, q])}
							onMoveUp={() => handleUp(i)}
							onMoveDown={() => handleDown(i)}
							onRemove={() => handleRemove(i)}
						/>)}
					</SortableContext>
				</DndContext>}
			
			</MainContainer>
		</WatermarkContext.Provider>
	);
}
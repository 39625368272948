import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

export default function BroadcastTabs(props) {
	const tabs = [
		{label: 'Settings', path: ''},
		{label: <Box className={'tab-with-icon'}>Control Room <SettingsRemoteIcon className={'tab-icon'} fontSize={'small'} color={'secondary'}/></Box>, path: 'control-room'}
	];
	
	const tabsEl = tabs.map((t, i) => <Tab
		value={t.path}
		label={t.label}
		key={`tab-${i}`}
		id={`tab-${i}`}
		aria-controls={`tabpanel-${i}`}
		onClick={() => props.onClick && props.onClick(t.path)}
	/>);
	
	return <Tabs centered value={props.selected} aria-label='Broadcast Editor Sections' className={'broadcast-nav-tabs'}>{tabsEl}</Tabs>
}

BroadcastTabs.propTypes = {
	onClick: PropTypes.func,
	selected: PropTypes.string
};

import React, {useContext, useState} from 'react';
import BoxLayout from '../components/AccountBox';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Alert from '@mui/material/Alert'
import api from '../../common/ApiRequest';
import authService from '../../auth/AuthService';
import UserContext from '../../common/UserContext';
import {LoadingButton} from '@mui/lab';

export default function ProfileComponent() {
	const [user, setUser] = useContext(UserContext);
	const [name, setName] = useState(user.name);
	const [email, setEmail] = useState(user.email);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	
	/**
	 * Send data to the server.
	 */
	const handleSubmit = () => {
		setError(null);
		setSuccess(false);
		setLoading(true);
		
		api.post('/account/', {
			name: name,
			email: email
		})
			.then(userData => {
				authService.setUser(userData);
				setUser(userData);
				setSuccess(() => true);
			})
			.catch(e => setError(() => e.toString()))
			.finally(() => setLoading(() => false));
	};
	
	/**
	 * Render.
	 */
	return (
		<BoxLayout title={'My Profile'}>
			<ValidatorForm className={'form'} noValidate onSubmit={() => handleSubmit()}>
				<TextValidator
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='name'
					label='Full Name'
					name='name'
					autoComplete='name'
					autoFocus
					value={name}
					validators={['required']}
					errorMessages={['this field is required']}
					onChange={event => setName(event.target.value)}
				/>
				<TextValidator
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					label='Email Address'
					name='email'
					autoComplete='email'
					value={email}
					validators={['required', 'isEmail']}
					errorMessages={['this field is required', 'email is not valid']}
					onChange={event => setEmail(event.target.value)}
				/>
				
				<LoadingButton
					type='submit'
					fullWidth
					variant='contained'
					color='primary'
					className={'submit'}
					loading={loading}
				>
					Save Changes
				</LoadingButton>
				
				{error && <Alert severity='error' variant='filled'>{error}</Alert>}
				{success && <Alert severity='success' variant='filled'>All changes saved</Alert>}
			
			</ValidatorForm>
		</BoxLayout>
	);
}

import React from 'react';
import {Routes, Route} from 'react-router-dom';
import YoutubeConnect from './pages/YoutubeConnect';
import YoutubeCallback from './pages/YoutubeCallback';

export default function YoutubeRoutes() {
	return (
		<Routes>
			<Route path={`connect`} element={<YoutubeConnect/>}/>
			<Route path={`callback`} element={<YoutubeCallback/>}/>
		</Routes>
	)
}
import React from 'react';
import SettingsBox from '../SettingsBox';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import SettingsFormControl from '../SettingsFormControl';
import LiveStreamPicker from './LiveStreamPicker';

export default function LiveStreamsSettingsBox({disabled = false, ...props}) {
	const livestreams = props.livestreams;
	
	const setLivestreams = livestreams => props.onChange(livestreams);
	
	const addLivestream = () => {
		setLivestreams([...livestreams, {}]);
	}
	
	const onChange = (index, livestream) => {
		const newList = [...livestreams];
		newList[index] = {...livestream};
		setLivestreams(newList);
	}
	
	const onDelete = index => {
		const newList = [...livestreams];
		newList.splice(index, 1);
		setLivestreams(newList);
	};
	
	return (
		<SettingsBox title={'Live Streams'}>
			{livestreams.map((livestream, i) => <LiveStreamPicker
					key={`ls${i}`}
					channels={props.channels || []}
					disabled={props.disabled}
					livestream={livestream}
					onChange={livestream => onChange(i, livestream)}
					onDelete={() => onDelete(i)}
					onError={props.onError}
				/>
			)}
			<SettingsFormControl style={{alignItems: 'center'}}>
				<Button variant={'contained'} onClick={addLivestream} disabled={props.disabled}>Add Live Stream</Button>
			</SettingsFormControl>
		</SettingsBox>
	);
}

LiveStreamsSettingsBox.propTypes = {
	channels: PropTypes.array,
	disabled: PropTypes.bool,
	livestreams: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	onError: PropTypes.func
};

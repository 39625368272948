import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress, Tooltip, useTheme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import api from '../../common/ApiRequest';
import Notification from '../../common/components/Notification';
import fileDownload from 'js-file-download';

export default function ExportQuestions(props) {
	const quizId = props.quizId;
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	
	const onClick = async () => {
		setLoading(true);
		try {
			const data = await api.download(`/apps/quiz/${quizId}/question/export`);
			fileDownload(data, 'quiz-questions.csv');
		} catch (e) {
			setError(() => e.toString());
		} finally {
			setLoading(() => false);
		}
	}
	
	return (<>
			{error && <Notification severity={'error'} onClear={() => setError(null)} message={error}/>}
			<Tooltip title={'Export Questions'}>
				<div>
					{!loading && <IconButton size='large' onClick={onClick}>
						<GetAppIcon/>
					</IconButton>}
					{loading && <CircularProgress size={16} style={{margin: `${theme.spacing(1.2)} ${theme.spacing(2)}`}}/>}
				</div>
			</Tooltip>
		</>
	);
}

ExportQuestions.propTypes = {
	quizId: PropTypes.string.isRequired
}
import React, {useCallback, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';

let searchTimeout;

export default function QuizPicker({label = 'Inherit from: ', onChange, onError, ignoreQuizIds = [], ...props}) {
	
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(props.selected || null);
	const [options, setOptions] = useState(selected ? [selected] : []);
	
	const [loading, setLoading] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	
	const onSelect = useCallback(selected => {
		setSelected(selected);
		onChange && onChange(selected);
	}, [onChange]);
	
	useEffect(() => {
		let active = true;
		clearTimeout(searchTimeout);
		
		searchTimeout = setTimeout((async () => {
			
			if (selected && selected.name === searchKey) {
				return;
			}
			
			try {
				setLoading(true);
				const searchParams = {keyword: searchKey, fields: ['_id', 'name']};
				const response = await api.get(`/apps/quiz/`, searchParams);
				
				if (active) {
					const items = (response.data || []).filter(item => !ignoreQuizIds.includes(item._id));
					setOptions(items);
				}
			} catch (e) {
				if (active) {
					onError && onError(e);
				}
			} finally {
				if (active) {
					setLoading(false);
				}
			}
		}), 500);
		
		return () => {
			active = false;
		};
	}, [searchKey, open, onSelect, ignoreQuizIds, selected, onError]);
	
	return (<div className={'quiz-picker'}>
			{label}
			<Autocomplete
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				onChange={(event, newValue) => {
					if (typeof newValue === 'object') {
						onSelect(newValue);
					}
				}}
				onInputChange={(event, newInputValue) => setSearchKey(newInputValue)}
				autoComplete
				freeSolo={true}
				filterOptions={(x) => x}
				value={selected}
				isOptionEqualToValue={(option, value) => value && option && option._id === value._id}
				getOptionLabel={(option) => option.name || ''}
				options={options}
				loading={loading}
				size={'small'}
				renderInput={(params) => (
					<TextField
						fullWidth
						{...params}
						variant='outlined'
					/>
				)}
			/>
		</div>
	);
}

QuizPicker.propTypes = {
	ignoreQuizIds: PropTypes.array,
	label: PropTypes.string,
	onChange: PropTypes.func,
	onError: PropTypes.func,
	selected: PropTypes.object
}

import React, {useState} from 'react';
import ColorPicker from './mui-color-picker/ColorPicker';
import PropTypes from 'prop-types';

export default function AppColorPicker({className = '', ...props}) {
	const [open, setOpen] = useState(false);
	return (
		<div className={`app-color-picker ${className}`}>
			<ColorPicker
				{...props}
				className={'picker'}
				open={open}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
			/>
			<div className={'preview'} style={{backgroundColor: props.value}} onClick={() => setOpen(true)}></div>
		</div>
	)
}

AppColorPicker.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onClose: PropTypes.func,
	onOpen: PropTypes.func,
	convert: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string,
	hintText: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	floatingLabelText: PropTypes.string,
	TextFieldProps: PropTypes.object,
	open: PropTypes.bool
}

import React, {useState} from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import SettingsFormControl from '../../../common/components/settings/SettingsFormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {ValidatorForm} from 'react-material-ui-form-validator';
import AnswerScreen from '../../../common-libs/quiz/screens/AnswerScreen';
import MediaPicker from '../../../common/components/media/MediaPicker';
import AppColorPicker from '../../../common/components/settings/AppColorPicker';
import SoundPicker from '../../../common/components/settings/SoundPicker';
import Scale from '../../../common/components/Scale';
import QuestionPicker from '../../components/QuestionPicker';

const sounds = {
	'music/8th-world-wonder': '8th World Wonder',
	'music/dorris-day': 'Dorris Day',
	'music/end-of-the-rainbow': 'End of The Rainbow',
	'music/fun-activity-montage': 'Fun Activity Montage',
	'music/happy-slaps-back': 'Happy Slaps Back',
	'music/headphones': 'Headphones',
	'music/higher-kiss': 'Higher Kiss',
	'music/limo-ride': 'Limo Ride',
	'music/my-achy-heart': 'My Achy Heart',
	'music/night-run-away': 'Night Run Away',
	'music/swing-house': 'Swing House',
	'music/yellow-rose-of-berkeley': 'Yellow Rose of Berkeley'
};

const defaultPreview = {
	_id: 'demo',
	text: 'Question',
	position: 1,
	options: [{text: 'Option 1', correct: true}, {text: 'Option 2'}, {text: 'Option 3'}, {text: 'Option 4'}]
};

export default function AnswerStyleTab(props) {
	const model = props.model;
	const [currentQuestion, setCurrentQuestion] = useState(null);
	
	const previewData = {
		...model,
		numQuestionsAsked: currentQuestion ? currentQuestion.position : 0,
		totalQuestions: model.numQuestions || 1
	};
	
	return (
		<Grid container className={'preview-style-tab answer-style-tab'} spacing={2}>
			<Grid size={3}>
				<Paper className={'side'}>
					<ValidatorForm noValidate autoComplete='off' onSubmit={props.onSubmit}>
						
						<SettingsFormControl>
							<AppColorPicker
								variant='outlined'
								label='Answer Text Color'
								value={model.screens.answer.color}
								TextFieldProps={{value: model.screens.answer.color}}
								onChange={value => value && props.onChange('screens.answer.color', value)}
							/>
						</SettingsFormControl>
						
						<SettingsFormControl>
							<AppColorPicker
								variant='outlined'
								label='Answer Background Color'
								value={model.screens.answer.textBgColor}
								TextFieldProps={{value: model.screens.answer.textBgColor}}
								onChange={value => value && props.onChange('screens.answer.textBgColor', value)}
							/>
						</SettingsFormControl>
						
						<SettingsFormControl>
							<AppColorPicker
								variant='outlined'
								label='Screen Background Color'
								value={model.screens.answer.bgColor}
								TextFieldProps={{value: model.screens.answer.bgColor}}
								onChange={value => value && props.onChange('screens.answer.bgColor', value)}
							/>
						</SettingsFormControl>
						
						<SettingsFormControl variant={'outlined'}>
							<SoundPicker
								sounds={sounds}
								value={model.screens.answer.sound}
								onChange={value => props.onChange('screens.answer.sound', value)}
							/>
						</SettingsFormControl>
						
						<SettingsFormControl>
							<MediaPicker
								userId={model.user}
								title={'Select Background'}
								media={model.screens.answer.background}
								onSelect={media => props.onChange('screens.answer.background', media)}
							/>
						</SettingsFormControl>
						
						<Box>
							<Button
								variant='contained'
								color='primary'
								disabled={props.saving}
								type={'submit'}
							>
								Save Changes
							</Button>
						</Box>
					</ValidatorForm>
				</Paper>
			</Grid>
			
			<Grid size={9}>
				<Paper className={'question-select'}>
					<SettingsFormControl variant={'outlined'}>
						<QuestionPicker
							quizId={model._id}
							label={'Preview Answer'}
							onChange={q => q ? setCurrentQuestion(q) : setCurrentQuestion(defaultPreview)}
						/>
					</SettingsFormControl>
				</Paper>
				<Scale selector={'.quiz-screen'} className={'preview'}>
					{currentQuestion && <AnswerScreen
						key={`preview-${currentQuestion._id}`}
						quiz={previewData}
						question={currentQuestion}
					/>}
				</Scale>
			</Grid>
		</Grid>
	)
}

import React from 'react';
import {IconButton, Tooltip} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';

export default function TogglePreviewButton(props) {
	const visible = props.visible;
	const onClick = () => {
		if (props.onToggle) {
			props.onToggle(!visible);
		}
	};
	
	return (
		<Tooltip title={visible ? 'Turn off preview' : 'Turn on preview'}>
			<IconButton size={'large'} onClick={onClick}>
				{visible ?
					<VisibilityIcon color={'success'} fontSize={'large'}/> :
					<VisibilityOffIcon color={'secondary'} fontSize={'large'}/>
				}
			</IconButton>
		</Tooltip>
	)
}

TogglePreviewButton.propTypes = {
	visible: PropTypes.bool,
	onToggle: PropTypes.func
}

TogglePreviewButton.defaultTypes = {
	visible: true
};
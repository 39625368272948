import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import {ListItemIcon, ListItemText, Tooltip} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ScoresPopup from '../components/ScoresPopup';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Notification from '../../common/components/Notification';
import DuplicateDialog from '../components/DuplicateDialog';

export default function MoreActionsButton(props) {
	const app = props.app;
	const [anchorEl, setAnchorEl] = useState(null);
	const [scoresOpen, setScoresOpen] = useState(false);
	const [message, setMessage] = useState(null);
	const open = Boolean(anchorEl);
	const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
	
	const openDuplicateDialog = () => {
		setDuplicateDialogOpen(() => true);
		setAnchorEl(() => null);
	}
	
	const openMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const closeMenu = () => {
		setAnchorEl(null);
	};
	
	const closeScores = () => {
		setScoresOpen(() => false);
		setAnchorEl(() => null);
	}
	
	return (
		<>
			<Tooltip title={'More actions'}>
				<IconButton
					aria-label='more'
					id='more-button'
					aria-controls='more-menu'
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup='true'
					onClick={openMenu}
					size={'small'}
				>
					<MoreVertIcon fontSize={'small'}/>
				</IconButton>
			</Tooltip>
			
			<Menu
				id='more-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={closeMenu}
			>
				<MenuItem onClick={() => setScoresOpen(true)}>
					<ListItemIcon>
						<EmojiEventsIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText>View Scores</ListItemText>
				</MenuItem>
				
				<CopyToClipboard
					text={app.clientUrl}
					onCopy={() => setMessage('Copied to clipboard')}
				>
					<MenuItem>
						<ListItemIcon>
							<LiveTvIcon fontSize='small'/>
						</ListItemIcon>
						<ListItemText>
							Copy Source URL
						</ListItemText>
					</MenuItem>
				</CopyToClipboard>
				
				<MenuItem onClick={openDuplicateDialog}>
					<ListItemIcon>
						<ContentCopyIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText>Duplicate</ListItemText>
				</MenuItem>
			</Menu>
			
			<DuplicateDialog
				app={app}
				open={duplicateDialogOpen}
				onClose={() => setDuplicateDialogOpen(false)}
			/>
			
			{<ScoresPopup app={app} open={scoresOpen} onClose={closeScores}/>}
			<Notification severity={'success'} message={message} onClear={() => setMessage(null)}/>
		</>
	);
}

MoreActionsButton.propTypes = {
	app: PropTypes.object.isRequired
}
import React, {useEffect, useState} from 'react';
import api from '../../common/ApiRequest';
import PagePreloader from '../../common/components/PagePreloader';
import {useLocation, useNavigate} from 'react-router-dom';

export default function TwitchCallback() {
	
	const [error, setError] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();
	
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		api.post('/twitch/finalize', {
			code: query.get('code')
		})
			.then(() => navigate('/channel/'))
			.catch(e => setError(e.toString()));
	}, [location.search, navigate]);
	
	return <PagePreloader error={error}/>
}

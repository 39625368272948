import React from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';

export default function FinalScreen(props) {
	const screen = props.quiz.screens.final;
	
	return <Screen
		className={`final`}
		mediaBg={screen.background}
	/>
}

FinalScreen.propTypes = {
	quiz: PropTypes.object.isRequired
}
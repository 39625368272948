import React from 'react';
import SettingsBox from '../../common/components/settings/SettingsBox';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

export default function FinalScreenBox(props) {
	
	const model = props.model;
	const onChange = props.onChange;
	
	return (
		<SettingsBox title={'Final Screen'}>
			<SettingsFormControl variant={'outlined'}>
				<InputLabel required style={{backgroundColor: '#fff'}}>Final Screen Type</InputLabel>
				<Select
					required
					value={model.screens.final.screenType}
					onChange={e => onChange('screens.final.screenType', e.target.value)}
				>
					<MenuItem key={`fs1`} value='scores'>Scores</MenuItem>
					<MenuItem key={`fs2`} value='media'>Media</MenuItem>
				</Select>
			</SettingsFormControl>
			<SettingsFormControl>
				<TextField required
				           variant='outlined'
				           type='number'
				           InputProps={{
					           inputProps: {
						           min: 10
					           }
				           }}
				           label='Duration'
				           helperText='Time in seconds. Effective only if "Loop Questions" is turned on.'
				           value={model.screens.final.duration}
				           onChange={e => onChange('screens.final.duration', e.target.value)}
				/>
			</SettingsFormControl>
		</SettingsBox>
	)
}

FinalScreenBox.propTypes = {
	model: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
}
import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import fileDownload from 'js-file-download';
import {stringify} from 'csv-stringify/browser/esm';

export default function ExportQuestions(props) {
	const model = props.model;
	
	const onClick = async () => {
		const data = [];
		if (model.questions) {
			model.questions.forEach(question => {
				const row = [question.text];
				if (question.options) {
					question.options.forEach(option => row.push(option.text));
				}
				data.push(row);
			})
		}
		
		stringify(data, function (err, output) {
			fileDownload(output, 'poll-questions.csv');
		});
	}
	
	return (<>
			<Tooltip title={'Export Questions'}>
				<div className={'poll-export-btn'}>
					<IconButton size='large' onClick={onClick}>
						<GetAppIcon/>
					</IconButton>
				</div>
			</Tooltip>
		</>
	);
}

ExportQuestions.propTypes = {
	model: PropTypes.object.isRequired
}

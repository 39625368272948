import React from 'react';
import PropTypes from 'prop-types';
import ResumeButton from './ResumeButton';
import PauseButton from './PauseButton';

export default function PauseResumeButton(props) {
	
	const app = props.app;
	
	return app.status === 'paused'
		? <ResumeButton
			appId={app._id}
			onChange={props.onChange}
			onError={props.onError}
		/>
		: <PauseButton
			disabled={app.status !== 'running'}
			appId={app._id}
			onChange={props.onChange}
			onError={props.onError}
		/>
}

PauseResumeButton.propTypes = {
	app: PropTypes.object.isRequired,
	onError: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
}
import React, {useState} from 'react';
import {Chip, ListItemText, Stack} from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

export default function TeamCard(props) {
	const team = props.team;
	const chatOptions = team.chatOptions || [];
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	
	const openMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const closeMenu = () => {
		setAnchorEl(null);
	};
	
	const onDelete = () => {
		closeMenu();
		props.onDelete();
	}
	
	const onEdit = () => {
		closeMenu();
		props.onEdit();
	}
	
	return (<>
			<Card>
				<CardHeader
					avatar={
						<Avatar sx={{bgcolor: team.color}} aria-label={'team'}>{team.name.charAt(0)}</Avatar>
					}
					action={
						<IconButton aria-label={'settings'} onClick={openMenu}>
							<MoreVertIcon/>
						</IconButton>
					}
					title={team.name}
				/>
				
				{(team.img && team.img.thumbSrc) && <CardMedia
					component={'img'}
					height={'194'}
					image={team.img.thumbSrc}
					alt={team.name}
				/>}
				
				<CardContent>
					<Typography variant={'body2'} textAlign={'center'}>Chat Commands</Typography>
					<Stack direction={'row'} spacing={1} justifyContent={'center'} mt={2}>
						{chatOptions.map((o, i) => <Chip label={o} variant={'outlined'} key={`co-${i}`}/>)}
					</Stack>
				</CardContent>
			</Card>
			
			<Menu
				id='team-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={closeMenu}
			>
				<MenuItem onClick={onEdit}>
					<ListItemText>Edit</ListItemText>
				</MenuItem>
				
				<MenuItem onClick={onDelete}>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
}
import React from 'react';
import Grid from '@mui/material/Grid2';
import ProfileComponent from '../components/ProfileComponent';
import PasswordComponent from '../components/PasswordComponent';
import MainContainer from '../../common/components/MainContainer';

export default function AccountProfile() {
	return (
		<MainContainer maxWidth={'md'}>
			<Grid container spacing={5}>
				<Grid size={6}>
					<ProfileComponent/>
				</Grid>
				<Grid size={6}>
					<PasswordComponent/>
				</Grid>
			</Grid>
		</MainContainer>
	);
}

export function createEmptyQuestion() {
	return {
		text: '',
		mediaQuestion: null,
		mediaAnswer: null,
		options: [
			{text: '', chatOptions: ['1', 'A'], correct: false},
			{text: '', chatOptions: ['2', 'B'], correct: false},
			{text: '', chatOptions: ['3', 'C'], correct: false},
			{text: '', chatOptions: ['4', 'D'], correct: false}
		]
	};
}
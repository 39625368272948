import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import api from '../ApiRequest';
import {LoadingButton} from '@mui/lab';
import {filesize} from 'filesize';

export default function UploadButton(
	{
		accept = 'image/*',
		className = '',
		color = 'primary',
		id = 'contained-button-file',
		maxSize = +process.env.REACT_APP_IMG_MAX_SIZE,
		onComplete,
		onError,
		title = 'Upload Image',
		uploadData,
		uploadUrl = '/image/upload',
		...props
	}) {
	
	const [uploading, setUploading] = useState(false);
	const [target, setTarget] = useState(null);
	
	const handleUpload = e => {
		if (uploading || !e.target) {
			return;
		}
		
		const size = e.target.files[0].size;
		if (maxSize && size > maxSize) {
			onError(`Max file size is ${filesize(maxSize)}`);
			return;
		}
		
		setUploading(true);
		setTarget(() => e.target);
		
		if (props.onStart) {
			props.onStart();
		}
	}
	
	useEffect(() => {
		let active = true;
		
		if (target) {
			(async () => {
				try {
					const response = await api.upload(uploadUrl, target.files[0], uploadData);
					if (active) {
						setUploading(() => false);
						onComplete(response);
					}
				} catch (e) {
					if (active) {
						onError(e);
					}
				} finally {
					setUploading(() => false);
					target.value = null;
					setTarget(() => null);
				}
			})();
		}
		
		return () => {
			active = false;
		}
	}, [target, uploadData, uploadUrl, onComplete, onError]);
	
	return (
		<div className={className}>
			<input
				accept={accept}
				id={id}
				type='file'
				style={{display: 'none'}}
				onChange={handleUpload}
				disabled={props.disabled}
			/>
			{<label htmlFor={id}>
				<LoadingButton
					variant='contained'
					color={color}
					loading={uploading}
					component={'span'}
					disabled={props.disabled}
				>
					{title}
				</LoadingButton>
			</label>}
		</div>
	)
}

UploadButton.propTypes = {
	accept: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	maxSize: PropTypes.number,
	onComplete: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	onStart: PropTypes.func,
	title: PropTypes.string,
	uploadData: PropTypes.object,
	uploadUrl: PropTypes.string
}

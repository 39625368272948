import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import moment from 'moment';
import {Typography} from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import PropTypes from 'prop-types';
import DeleteButton from '../../common/buttons/DeleteButton'
import Avatar from '@mui/material/Avatar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BlockIcon from '@mui/icons-material/Block';
import authService from '../../auth/AuthService';
import {numberWithCommas} from '../../common-libs/utils/format';
import SocialIcon from '../../common/components/SocialIcon';

export default function ChannelTable({component = Paper, ...props}) {
	const items = props.items;
	const user = authService.getCurrentUser();
	const getSubscribers = item => item.subscriberCount ? numberWithCommas(+item.subscriberCount) : '';
	
	return (
		<TableContainer component={component}>
			<Table aria-label='Channels' className={'app-table'}>
				<TableHead>
					<TableRow>
						<TableCell>Thumb</TableCell>
						<TableCell>Type</TableCell>
						<TableCell>Name</TableCell>
						<TableCell>Subscribers</TableCell>
						{user.role !== 'creator' && <TableCell>Added By</TableCell>}
						<TableCell>URL</TableCell>
						<TableCell>Added On</TableCell>
						<TableCell align={'center'}>Healthy</TableCell>
						<TableCell align={'center'}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(items && items.length === 0) && <TableRow>
						<TableCell colSpan={user.role !== 'creator' ? 8 : 7}><Typography align={'center'}>Nothing found</Typography></TableCell>
					</TableRow>}
					{items && items.map(item => (
						<TableRow key={item._id}>
							<TableCell><Avatar alt={item.title} src={item.thumbUrl} variant={'rounded'}/></TableCell>
							<TableCell align={'center'}>
								<SocialIcon type={item.socialType}/>
							</TableCell>
							<TableCell component='th' scope='row'>
								{item.title}
							</TableCell>
							<TableCell>{getSubscribers(item)}</TableCell>
							{user.role !== 'creator' && <TableCell>
								{item.addedBy && item.addedBy.name}
							</TableCell>}
							<TableCell>
								<a href={item.url} target={'_blank'} rel={'noreferrer'}>{item.url}</a>
							</TableCell>
							<TableCell>
								{item.createdAt && moment(Date.parse(item.createdAt)).format('LLL')}
							</TableCell>
							<TableCell align={'center'}>
								{item.healthy ? <CheckCircleOutlineIcon color={'success'}/> : <BlockIcon color={'secondary'}/>}
							</TableCell>
							<TableCell align={'center'}>
								<DeleteButton
									apiUrl={`/channel/${item._id}`}
									message={'Are you sure you want to delete this channel? This action is irreversible.'}
									onError={props.onDeleteError}
									onSuccess={props.onDeleteSuccess}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

ChannelTable.propTypes = {
	items: PropTypes.array,
	component: PropTypes.any,
	deleteButton: PropTypes.bool,
	onDeleteError: PropTypes.func,
	onDeleteSuccess: PropTypes.func
}

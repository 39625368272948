import React from 'react';
import PauseIcon from '@mui/icons-material/Pause';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import AjaxButton from '../../common/buttons/AjaxButton';

export default function PauseButton({title = 'Pause', disabled = false, ...props}) {
	return <AjaxButton
		ariaLabel={'pause'}
		exec={() => api.post(`/apps/broadcast/${props.appId}/pause`)}
		icon={PauseIcon}
		title={title}
		disabled={disabled}
		{...props}
	/>
}

PauseButton.propTypes = {
	title: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onError: PropTypes.func
}

const regions = [
	{title: 'New York', slug: 'nyc1', countryCode: 'US'}, // United States
	{title: 'San Francisco', slug: 'sfo3', countryCode: 'US'}, // United States
	{title: 'Toronto', slug: 'tor1', countryCode: 'CA'}, // Canada
	{title: 'Amsterdam', slug: 'ams3', countryCode: 'NL'}, // Netherlands
	{title: 'Frankfurt', slug: 'fra1', countryCode: 'DE'}, // Germany
	{title: 'London', slug: 'lon1', countryCode: 'GB'}, // United Kingdom
	{title: 'Sydney', slug: 'syd1', countryCode: 'AU'} // Australia
];

export function getAllRegions() {
	return regions;
}

export function getRegion(slug) {
	return regions.find(r => r.slug === slug)
}

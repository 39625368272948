import React from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';

/** @jsxImportSource @emotion/react */

export default function AnswerScreen(props) {
	const quiz = props.quiz;
	const screen = quiz.screens.answer;
	const question = props.question;
	const options = question.options;
	
	return (
		<Screen
			className={`answer`}
			bgColor={screen.bgColor}
			mediaBg={question.mediaAnswer || question.mediaQuestion || screen.background}
			css={{
				'& .question-text': {
					fontSize: `${props.quiz.screens.question.question.fontSize}px`,
					color: props.quiz.screens.question.question.color,
					backgroundColor: props.quiz.screens.question.question.bgColor,
				},
				'& .option': {
					fontSize: `${props.quiz.screens.question.option.fontSize}px`,
					color: props.quiz.screens.question.option.color,
					backgroundColor: props.quiz.screens.question.option.bgColor,
					'&.correct': {
						color: props.quiz.screens.answer.color,
						backgroundColor: props.quiz.screens.answer.textBgColor
					}
				}
			}}
		>
			<div className={`question-text`}>
				{quiz.screens.question.displayProgress && `${quiz.numQuestionsAsked}/${quiz.totalQuestions}: `}
				{question.text}
			</div>
			<div className={`options`}>
				{options.map((a, i) => <div key={i} className={`option ${a.correct && 'correct'}`}>
					<div className={`option-index`}>{i + 1}</div>
					<div className={`option-text`}>{a.text}</div>
				</div>)}
			</div>
		</Screen>
	)
}

AnswerScreen.propTypes = {
	question: PropTypes.object.isRequired,
	quiz: PropTypes.object.isRequired
}

import React, {useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import UserContext from '../UserContext';
import QuizIcon from '@mui/icons-material/Quiz';
import PollIcon from '@mui/icons-material/Poll';
import CellTowerIcon from '@mui/icons-material/CellTower';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GroupIcon from '@mui/icons-material/Group';
import objectPath from 'object-path';

export default function Header() {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [user] = useContext(UserContext);
	
	const goTo = path => {
		handleClose();
		navigate(path);
	}
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const canAccess = appType => ['admin'].includes(user.role) || objectPath.get(user, `permissions.${appType}`);
	
	return (<AppBar position='static' className={'app-header'}>
		<Toolbar>
			<Link component={RouterLink} to='/' variant='h6' className={'title'} color={'inherit'}>StreamSmart Console</Link>
			<div className={'menu'}>
				
				{canAccess('quiz') && <Button
					startIcon={<QuizIcon/>}
					size={'small'}
					className={'button'}
					component={RouterLink} to='/quiz/'
				>
					Quizzes
				</Button>}
				
				{canAccess('poll') && <Button
					startIcon={<PollIcon/>}
					size={'small'}
					className={'button'}
					component={RouterLink} to='/poll/'
				>
					Polls
				</Button>}
				
				{canAccess('broadcast') && <Button
					startIcon={<CellTowerIcon/>}
					size={'small'}
					className={'button'}
					component={RouterLink} to='/broadcast/'
				>
					Broadcasts
				</Button>}
				
				<Button
					startIcon={<YouTubeIcon/>}
					size={'small'}
					className={'button'}
					component={RouterLink} to='/channel/'
				>
					Channels
				</Button>
				
				{user.role === 'admin' && <Button
					startIcon={<GroupIcon/>}
					size={'small'}
					className={'button'}
					component={RouterLink} to='/user/'
				>
					Users
				</Button>}
			</div>
			<div className={'right-menu'}>
				<IconButton
					aria-label='account of current user'
					aria-controls='menu-appbar'
					aria-haspopup='true'
					onClick={handleMenu}
					color='inherit'
					size='large'>
					<AccountCircle/>
					<Typography variant='body1' className={'user-name'}>{user.name}</Typography>
				</IconButton>
				<Menu
					id='menu-appbar'
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top', horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top', horizontal: 'right',
					}}
					open={open}
					onClose={handleClose}
					className={'menu-drop-down'}
				>
					<MenuItem onClick={() => goTo('/account/')}>Profile</MenuItem>
					<MenuItem onClick={() => goTo('/auth/logout')}>Logout</MenuItem>
				</Menu>
			</div>
		</Toolbar>
	</AppBar>);
}

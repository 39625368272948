import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Typography} from '@mui/material';
import MainContainer from '../../common/components/MainContainer';
import Alert from '@mui/material/Alert';
import ClipboardButton from '../../common/buttons/ClipboardButton';

export default function InfoTab(props) {
	const model = props.model;
	return (
		<div className={'app-info-tab'}>
			<MainContainer>
				<Alert severity={'info'}>
					In order for quiz to work properly - both <strong>YouTube</strong> live stream and <strong>Streaming Software</strong> require to be configured as described below
				</Alert>
			</MainContainer>
			
			<MainContainer>
				<Paper className={'instructions'}>
					<Typography variant={'h5'}>Configure YouTube live stream</Typography>
					
					<ol>
						<li>
							<Typography>Create YouTube live stream as you would normally do</Typography>
						</li>
						<li>
							<Typography>Set Stream Latency to <strong>Ultra low-latency</strong></Typography>
						</li>
						<li>
							<Typography>Disable <strong>DVR</strong></Typography>
							<img alt={'Live Stream Settings'} src={'/images/yt-stream-settings.webp'}/>
						</li>
						<li>
							<Typography>Enable <strong>Live Chat</strong></Typography>
							<img alt={'Live Chat Settings'} src={'/images/yt-chat-settings.webp'}/>
						</li>
					</ol>
				</Paper>
			</MainContainer>
			
			<MainContainer>
				<Alert severity={'info'}>
					Both <strong>OBS Studio</strong> and <strong>Streamlabs OBS</strong> have similar interfaces.
					The screenshots were made in OBS Studio but the same steps will work for Streamlabs OBS as well
				</Alert>
			</MainContainer>
			
			<MainContainer>
				<Paper className={'instructions'}>
					<Typography variant={'h5'}>Configure Streaming Software</Typography>
					<ol>
						<li>
							<Typography>Configure OBS Studio / Streamlabs OBS as you would normally do</Typography>
						</li>
						<li>
							<Typography>Go to Preferences -> Video and set <strong>Base (Canvas) Resolution</strong> to 1280x720</Typography>
							<img alt={'OBS Canvas'} src={'/images/obs-canvas.webp'}/>
						</li>
						<li>
							<Typography>(Optional) We recommend to set <strong>Output (Canvas) Resolution</strong> to 1280x720 as well</Typography>
						</li>
						<li>
							<Typography>In Sources window click on <strong>+</strong> button and then on <strong>Browser</strong></Typography>
							<img alt={'OBS Canvas'} src={'/images/obs-browser-source.webp'}/>
						</li>
						<li>
							<Typography>Opt it <strong>Create new</strong> and enter "Quiz" as the name. Click on OK button</Typography>
						</li>
						<li>
							<Typography>In a new window enter the following settings:</Typography>
							<Typography component={'div'}>
								<strong>URL:</strong> {model.clientUrl}
								<ClipboardButton value={model.clientUrl} color={'default'} className={'copy-btn'}/>
							</Typography>
							<Typography><strong>Width:</strong> 1280</Typography>
							<Typography><strong>Height:</strong> 720</Typography>
							<Typography>Remove everything from <strong>Custom CSS</strong> textarea</Typography>
							<img alt={'OBS Browser Settings'} src={'/images/obs-browser-settings.webp'}/>
						</li>
					</ol>
				</Paper>
			</MainContainer>
			
			<MainContainer>
				<Paper className={'instructions'}>
					<Typography variant={'h5'}>Start The Quiz</Typography>
					<ol>
						<li>
							<Typography>Go to Control Room</Typography>
							<img alt={'Control Room Tab'} src={'/images/control-room-tab.webp'}/>
						</li>
						<li>
							<Typography>Click on "Start" button</Typography>
							<img alt={'Start Button'} src={'/images/start-button.webp'}/>
						</li>
					</ol>
				</Paper>
			</MainContainer>
		</div>
	)
}

InfoTab.propTypes = {
	model: PropTypes.object.isRequired
};

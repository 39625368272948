import React, {useEffect, useState} from 'react';
import api from '../../common/ApiRequest';
import PagePreloader from '../../common/components/PagePreloader';

export default function TwitchConnect() {
	
	const [error, setError] = useState(null);
	
	useEffect(() => {
		api.get('/twitch/auth-url')
			.then(response => {
				window.location.href = response.url
			})
			.catch(e => setError(e.toString()));
	}, []);
	
	return <PagePreloader error={error}/>
}

import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import {Box, IconButton, Paper} from '@mui/material';
import {TextValidator} from 'react-material-ui-form-validator';
import AppColorPicker from '../../common/components/settings/AppColorPicker';
import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import ChipInput from '../../common/components/settings/ChipInput';

export default function OptionForm({canMoveDown = true, canMoveUp = true, chatOptions = [], ...props}) {
	/**
	 * @param key
	 * @param val
	 */
	const onChange = (key, val) => {
		const model = {
			text: props.text,
			chatOptions: chatOptions,
			seriesColor: props.seriesColor,
			textColor: props.textColor
		};
		model[key] = val;
		props.onChange(model);
	}
	
	return (
		<Paper className={'option-form'}>
			
			<SettingsFormControl className={'form-control'}>
				<TextValidator
					label={'Option'}
					value={props.text}
					size='small'
					fullWidth
					required
					validators={['required']}
					errorMessages={['Chat command is required']}
					onChange={e => onChange('text', e.target.value)}
				/>
			</SettingsFormControl>
			
			<Box mb={2} className={'options'}>
				<ChipInput
					label={'Chat options'}
					required={true}
					inputProps={{variant: 'standard'}}
					errorMsg={!chatOptions.length ? 'Chat options are required' : null}
					onChange={values => onChange('chatOptions', values)}
					options={chatOptions}
				/>
			</Box>
			
			<SettingsFormControl className={'form-control'}>
				<AppColorPicker
					variant={'standard'}
					label='Color'
					value={props.seriesColor}
					TextFieldProps={{value: props.seriesColor}}
					onChange={v => onChange('seriesColor', v)}
				/>
			</SettingsFormControl>
			
			<SettingsFormControl className={'form-control'}>
				<AppColorPicker
					variant={'standard'}
					label='Text Color'
					value={props.textColor}
					TextFieldProps={{value: props.textColor}}
					onChange={v => onChange('textColor', v)}
				/>
			</SettingsFormControl>
			
			<Box>
				<IconButton
					aria-label='move up'
					disabled={!canMoveUp}
					onClick={() => props.onMoveUp()}
					size='small'
					title={'Move Up'}
				>
					<ArrowUpwardIcon fontSize='inherit'/>
				</IconButton>
				
				<IconButton
					aria-label='move down'
					disabled={!canMoveDown}
					onClick={() => props.onMoveDown()}
					size='small'
					title={'Move Down'}
				>
					<ArrowDownwardIcon fontSize='inherit'/>
				</IconButton>
				
				<IconButton
					aria-label='delete'
					color='secondary'
					onClick={() => props.onRemove()}
					size='small'
					title={'Remove'}
				>
					<DeleteIcon fontSize='inherit'/>
				</IconButton>
			</Box>
		</Paper>
	)
}

OptionForm.propTypes = {
	canMoveDown: PropTypes.bool,
	canMoveUp: PropTypes.bool,
	chatOptions: PropTypes.array,
	onMoveDown: PropTypes.func,
	onMoveUp: PropTypes.func,
	onRemove: PropTypes.func
}

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from '@mui/material';
import Button from '@mui/material/Button';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import {TextValidator} from 'react-material-ui-form-validator';
import {ValidatorForm} from 'react-material-ui-form-validator';
import Typography from '@mui/material/Typography';
import {LoadingButton} from '@mui/lab';
import Alert from '@mui/material/Alert';
import api from '../../common/ApiRequest';
import {useNavigate} from 'react-router-dom';

function DuplicateDialog(props) {
	
	const app = props.app;
	const open = props.open;
	
	const navigate = useNavigate();
	const [name, setName] = useState(`${app.name} (Copy)`);
	const [copyQuestions, setCopyQuestions] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	
	const onSubmit = () => {
		setError(() => null);
		setLoading(() => true);
		api
			.post(`/apps/quiz/${app._id}/duplicate`, {
					name,
					copyQuestions
				}
			)
			.then(newQuiz => navigate(`/quiz/${newQuiz._id}?duplicated=1`))
			.catch(e => {
				setError(() => e.toString());
				setLoading(() => false);
			});
	}
	
	return (
		
		<Dialog open={open} maxWidth={'xs'} fullWidth={true}>
			<ValidatorForm noValidate onSubmit={onSubmit}>
				<DialogTitle>Duplicate a quiz</DialogTitle>
				<DialogContent dividers>
					<SettingsFormControl>
						<TextValidator
							fullWidth
							required
							variant='outlined'
							label='New Name'
							validators={['required']}
							errorMessages={['Quiz name is required']}
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</SettingsFormControl>
					
					<FormControlLabel
						control={<Checkbox checked={copyQuestions} onChange={e => setCopyQuestions(e.target.checked)}/>}
						label={<Typography>Copy questions</Typography>}
					/>
					
					{error && <Alert severity={'error'}>{error}</Alert>}
				
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => props.onClose && props.onClose()}
						color={'secondary'}
						disabled={loading}
						variant={'contained'}
					>Cancel</Button>
					
					<LoadingButton
						loading={loading}
						type='submit'
						variant={'contained'}
					>Duplicate</LoadingButton>
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	)
}

DuplicateDialog.propTypes = {
	app: PropTypes.object.isRequired
}

export default DuplicateDialog;
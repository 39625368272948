import React, {useEffect, useState} from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Alert from '@mui/material/Alert'
import BoxLayout from '../components/AccountBox';
import api from '../../common/ApiRequest';
import {LoadingButton} from '@mui/lab';

let validatorSet = false;

export default function PasswordComponent() {
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	
	/**
	 * Send data to the server.
	 */
	const handleSubmit = () => {
		setError(null);
		setSuccess(false);
		setLoading(true);
		
		api.post('/account/password', {currentPassword, newPassword})
			.then(() => {
				setSuccess(() => true);
				setCurrentPassword(() => '');
			})
			.catch(e => setError(() => e.toString()))
			.finally(() => setLoading(() => false));
	};
	
	/**
	 * Password match validation.
	 */
	useEffect(() => {
		if (!validatorSet) {
			validatorSet = true;
			ValidatorForm.addValidationRule('isPasswordMatch', value => value === newPassword);
			ValidatorForm.addValidationRule('isPasswordNew', value => value !== currentPassword);
		}
		return () => {
			validatorSet = false;
			ValidatorForm.removeValidationRule('isPasswordMatch');
			ValidatorForm.removeValidationRule('isPasswordNew');
		};
	});
	
	/**
	 * Render.
	 */
	return (
		<BoxLayout title={'Change Password'}>
			<ValidatorForm className={'form'} noValidate onSubmit={() => handleSubmit()}>
				<TextValidator
					variant='outlined'
					margin='normal'
					required
					fullWidth
					name='curpassword'
					label='Current Password'
					type='password'
					id='curpassword'
					autoComplete='current-password'
					value={currentPassword}
					validators={['required']}
					errorMessages={['this field is required', 'password should be minimum 6 characters']}
					onChange={event => setCurrentPassword(event.target.value)}
				/>
				<TextValidator
					variant='outlined'
					margin='normal'
					required
					fullWidth
					name='password'
					label='New Password'
					type='password'
					id='password'
					autoComplete='new-password'
					value={newPassword}
					validators={['required', 'minStringLength:6', 'isPasswordNew']}
					errorMessages={['this field is required', 'password should be minimum 6 characters', 'matches your current password']}
					onChange={event => setNewPassword(event.target.value)}
				/>
				<TextValidator
					variant='outlined'
					margin='normal'
					required
					fullWidth
					name='password2'
					label='Confirm Password'
					type='password'
					id='password2'
					value={password2}
					autoComplete='confirm-password'
					validators={['isPasswordMatch']}
					errorMessages={['passwords do not match']}
					onChange={event => setPassword2(event.target.value)}
				/>
				
				<LoadingButton
					type='submit'
					fullWidth
					variant='contained'
					color='primary'
					className={'submit'}
					loading={loading}
				>
					Change Password
				</LoadingButton>
				
				{error && <Alert severity='error' variant='filled'>{error}</Alert>}
				{success && <Alert severity='success' variant='filled'>All changes saved</Alert>}
			
			</ValidatorForm>
		</BoxLayout>
	);
}
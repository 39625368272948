import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Box} from '@mui/material';

export default function ClipboardButton({className = '', color = 'primary', size = 'small', title = 'Copy to the clipboard', ...props}) {
	const [msg, setMsg] = useState(title);
	return (
		<Tooltip
			title={msg}
			onClose={() => setMsg(title)}
		>
			<Box className={className}>
				<CopyToClipboard
					text={props.value}
					onCopy={() => setMsg('Copied')}
				>
					<IconButton size={size} color={color}>
						{props.icon ? props.icon : <AssignmentIcon fontSize={size}/>}
					</IconButton>
				</CopyToClipboard>
			</Box>
		</Tooltip>
	);
}

ClipboardButton.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	icon: PropTypes.node,
	size: PropTypes.string,
	title: PropTypes.string,
	value: PropTypes.string.isRequired
}

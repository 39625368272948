import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import BackupIcon from '@mui/icons-material/Backup';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import UploadButton from '../../common/buttons/UploadButton';

export default function ImportQuestions(props) {
	const model = props.model;
	const [open, setOpen] = useState(false);
	const [msg, setMsg] = useState(null);
	
	const handleClose = () => {
		setOpen(false);
		setMsg(() => null);
	};
	
	const onComplete = response => {
		const numImported = response.numAdded;
		if (numImported <= 0) {
			setMsg({
				severity: 'warning',
				text: `Nothing was imported`
			});
		} else {
			setMsg({
				severity: 'success',
				text: `Imported ${numImported} question${numImported !== 1 ? 's' : ''}`
			});
			props.onComplete(response.questions);
		}
	};
	
	return <>
		<Tooltip title={'Import Questions'}>
			<IconButton onClick={() => setOpen(true)} size='large'>
				<BackupIcon/>
			</IconButton>
		</Tooltip>
		<Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
			<DialogTitle id='form-dialog-title'>Import Questions</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<span className={'import-data-list-item'}><b>•</b> Only <strong>csv</strong> files are allowed. Use this <a href={'/import-poll-questions.csv'}>file</a> as a template</span>
					<span className={'import-data-list-item'}><b>•</b> Do not add csv header</span>
					<span className={'import-data-list-item'}><b>•</b> Each question requires at least 2 options</span>
					<span className={'import-data-list-item'}><b>•</b> There is no limit to the number of options</span>
				</DialogContentText>
				{msg && <Alert severity={msg.severity}>{msg.text}</Alert>}
			</DialogContent>
			<DialogActions>
				<UploadButton
					accept={'.csv'}
					uploadUrl={`/apps/poll/${model._id}/import`}
					onComplete={onComplete}
					onError={e => setMsg({severity: 'error', text: e.toString()})}
					title={'Import File'}
				/>
				
				<Button onClick={handleClose} color='secondary' variant={'contained'} className={'import-data-close-btn'}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	</>;
}

ImportQuestions.propTypes = {
	model: PropTypes.object.isRequired,
	onComplete: PropTypes.func.isRequired
}
import React from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';
import TeamCard from '../components/TeamCard';

/** @jsxImportSource @emotion/react */
export default function ScoresScreenTeam(props) {
	const title = props.title;
	const teams = props.teams || [];
	const scores = props.scores || {};
	const styles = props.styles || {title: {}, page: {}};
	
	teams.forEach(team => {
		team.stats = scores[team._id] || {totalScores: 0, correctAnswers: 0, totalAnswers: 0};
		if (team.stats.totalAnswers > 0) {
			team.stats.percent = Math.round(team.stats.correctAnswers / team.stats.totalAnswers * 100);
		} else {
			team.stats.percent = 0;
		}
	});
	
	return <Screen
		className={`scores has-scores`}
		bgColor={styles.page.bgColor}
		mediaBg={styles.page.background}
		css={{
			'.screen-title': {
				fontSize: `${styles.title.fontSize}px`,
				color: styles.title.color,
				backgroundColor: styles.title.bgColor
			},
		}}
	>
		<div className={`screen-title`}>{title}</div>
		<div className={`screen-content`}>
			<div className={'teams-container'}>
				{teams.map((team, i) => <TeamCard team={team} key={`team${i}`}/>)}
			</div>
		</div>
	</Screen>
}

ScoresScreenTeam.propTypes = {
	title: PropTypes.string.isRequired,
	teams: PropTypes.array.isRequired,
	scores: PropTypes.object,
	styles: PropTypes.object
};

import React from 'react';
import QuizEditor from './components/QuizEditor';
import api from '../common/ApiRequest';
import {createEmptyQuiz, sanitize} from './services/QuizService';
import WithChannels from '../common/providers/WithChannels';
import Notification from '../common/components/Notification';
import auth from '../auth/AuthService';
import UserIdContext from '../common/UserIdContext';
import withLocation from '../common/providers/withLocation';

class QuizCreate extends React.Component {
	
	constructor(props, context) {
		super(props, context);
		this.state = {
			saving: false,
			error: null,
			model: createEmptyQuiz()
		}
	}
	
	onSubmit(model) {
		this.setState({
			saving: true
		});
		
		api.put('/apps/quiz', sanitize(model))
			.then(model => this.props.navigate(`/quiz/${model._id}/questions?created=1`))
			.catch(e => {
				this.setState({
					error: e.toString(),
					saving: false
				})
			});
	}
	
	render() {
		return (
			<UserIdContext.Provider value={auth.getCurrentUser()._id}>
				<Notification
					message={this.state.error}
					onClear={() => this.setState({error: null})}
					severity={'error'}
				/>
				<WithChannels userId={auth.getCurrentUser()._id}>
					<QuizEditor
						model={this.state.model}
						saving={this.state.saving}
						onSubmit={this.onSubmit.bind(this)}
						onError={error => this.setState({error})}
					/>
				</WithChannels>
			</UserIdContext.Provider>
		)
	}
}

export default withLocation(QuizCreate);
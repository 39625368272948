import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import moment from 'moment';
import Status from './Status';
import {Tooltip, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Link, useNavigate} from 'react-router-dom';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import PropTypes from 'prop-types';
import authService from '../../auth/AuthService';
import DeleteButton from '../../common/buttons/DeleteButton';
import LivestreamsTableCell from '../../common/components/table/LivestreamsTableCell'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AjaxButton from '../../common/buttons/AjaxButton';
import api from '../../common/ApiRequest';

export default function PollTable({component = Paper, ...props}) {
	const apps = props.apps;
	const user = authService.getCurrentUser();
	const navigate = useNavigate();
	
	return (
		<TableContainer component={component}>
			<Table aria-label='My Polls' className={'app-table'}>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						{user.role !== 'creator' && <TableCell>User</TableCell>}
						<TableCell>Live Streams</TableCell>
						<TableCell>Created</TableCell>
						<TableCell align={'center'}>Status</TableCell>
						<TableCell align='center'>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(apps && apps.length === 0) && <TableRow>
						<TableCell colSpan={user.role !== 'creator' ? 6 : 5}><Typography align={'center'}>Nothing found</Typography></TableCell>
					</TableRow>}
					{apps && apps.map(app => (
						<TableRow key={app._id}>
							<TableCell component='th' scope='row'>
								{app.name}
							</TableCell>
							{user.role !== 'creator' && <TableCell>{app.user && app.user.name}</TableCell>}
							
							<LivestreamsTableCell livestreams={app.livestreams}/>
							
							<TableCell>
								{moment(Date.parse(app.createdAt)).format('LLL')}
							</TableCell>
							<TableCell className={'status-cell'}>
								<Status model={app}/>
							</TableCell>
							<TableCell>
								<div className={'actions'}>
									<Tooltip title={'Control Room'}>
												<span>
													<IconButton
														aria-label='control-room'
														color='secondary'
														component={Link}
														to={`/poll/${app._id}/control-room`}
														size='small'
													>
														<SettingsRemoteIcon fontSize={'small'}/>
													</IconButton>
												</span>
									</Tooltip>
									
									<Tooltip title={'Edit'}>
										<IconButton
											aria-label='edit'
											color='primary'
											component={Link}
											to={`/poll/${app._id}`}
											size='small'>
											<EditIcon fontSize={'small'}/>
										</IconButton>
									</Tooltip>
									
									<Tooltip title={'Duplicate'}>
										<div>
											<AjaxButton
												exec={async () => await api.post(`/apps/poll/${app._id}/duplicate`)}
												icon={ContentCopyIcon}
												size={'small'}
												ariaLabel={'duplicate'}
												color={'primary'}
												progressColor={'primary'}
												progressSize={20}
												progressMargin={'5px'}
												onChange={newPoll => navigate(`/poll/${newPoll._id}?duplicated=1`)}
												onError={e => props.onError && props.onError(e)}
											/>
										</div>
									</Tooltip>
									
									<DeleteButton
										apiUrl={`/apps/poll/${app._id}`}
										message={'Are you sure you want to delete this poll? This action is irreversible.'}
										onError={props.onDeleteError}
										onSuccess={props.onDeleteSuccess}
									/>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

PollTable.propTypes = {
	apps: PropTypes.array,
	component: PropTypes.any,
	onDeleteError: PropTypes.func,
	onDeleteSuccess: PropTypes.func,
	onError: PropTypes.func
}

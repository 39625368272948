import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import authService from '../AuthService';
import {Navigate} from 'react-router-dom';
import {useTheme} from '@mui/material';

export default function AuthLayout(props) {
	const theme = useTheme();
	
	return (
		<>
			{authService.getCurrentUser() && <Navigate to={'/'}/>}
			<Container component='main' maxWidth='xs'>
				<CssBaseline/>
				<div style={{marginTop: theme.spacing(8), display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<Avatar style={{margin: theme.spacing(1), backgroundColor: theme.palette.secondary.main}}>
						<LockOutlinedIcon/>
					</Avatar>
					<Typography component='h1' variant='h5'>
						{props.title}
					</Typography>
					
					{props.children}
				</div>
			</Container>
		</>
	)
}
import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import QuestionPreview from './QuestionPreview';

export default function SortableItem(props) {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
	} = useSortable({
		id: props.question._id,
		transition: null
	});
	
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	
	return (
		<div ref={setNodeRef} style={style} {...attributes}>
			<QuestionPreview
				dragRef={setActivatorNodeRef}
				dragListeners={listeners}
				question={props.question}
				onLoad={props.onLoad}
				onMoveUp={props.onMoveUp}
				onMoveDown={props.onMoveDown}
				onRemove={props.onRemove}
			/>
		</div>
	);
}
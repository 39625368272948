import React, {useState} from 'react';
import StopIcon from '@mui/icons-material/Stop';
import IconButton from '@mui/material/IconButton';
import {CircularProgress, Dialog, DialogActions, DialogContent, Tooltip} from '@mui/material';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function StopButton({title = 'Stop', ...props}) {
	
	const [dialogOpen, setDialogOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const classes = {};
	
	const stopApp = () => {
		setLoading(true);
		
		api
			.post(`/apps/quiz/${props.appId}/stop`)
			.catch(e => props.onError && props.onError(e))
			.finally(() => {
				setLoading(false);
				props.onChange && props.onChange();
			});
	};
	
	/**
	 * On stop confirmation.
	 *
	 * @param decision
	 */
	const onConfirm = decision => {
		if (decision) {
			stopApp();
		}
		setDialogOpen(false);
	}
	
	return <>
		
		<Dialog
			maxWidth='xs'
			aria-labelledby='confirmation-dialog-title'
			open={dialogOpen}
		>
			<DialogContent className={classes.padding} dividers>
				Are you sure you want to stop this quiz?
			</DialogContent>
			<DialogActions className={classes.padding}>
				<Button onClick={() => onConfirm(false)} color='secondary' variant={'contained'}>No</Button>
				<Button onClick={() => onConfirm(true)} color='primary' variant={'contained'}>Yes</Button>
			</DialogActions>
		</Dialog>
		
		{loading
			? <CircularProgress
				color='secondary'
				size={27}
				style={{display: 'flex', alignItems: 'center', margin: '0 16px'}}
			/>
			: <Tooltip title={title}>
				<IconButton aria-label='start' color='secondary' onClick={() => setDialogOpen(true)} size='large'>
					<StopIcon fontSize={'large'}/>
				</IconButton>
			</Tooltip>
		}
	</>;
}

StopButton.propTypes = {
	appId: PropTypes.string,
	onChange: PropTypes.func,
	onError: PropTypes.func,
	title: PropTypes.string
}

import React from 'react';
import {Route, Routes} from 'react-router-dom';
import UserList from './pages/UserList';
import EditUser from './pages/EditUser';
import AddUser from './pages/AddUser';

import PrivateRoute from '../common/components/PrivateRoute';
import './style.scss';

export default function UserRoutes() {
	return (
		<PrivateRoute roles={['admin']} outputType={'children'}>
			<Routes>
				<Route path={`add`} element={<AddUser/>}/>
				<Route path={`:userId`} element={<EditUser/>}/>
				<Route index element={<UserList/>}/>
			</Routes>
		</PrivateRoute>
	)
}

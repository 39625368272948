import React from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import WelcomeScreen from '../../../common-libs/quiz/screens/WelcomeScreen';
import Alert from '@mui/material/Alert';
import SettingsFormControl from '../../../common/components/settings/SettingsFormControl';
import MediaPicker from '../../../common/components/media/MediaPicker';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {ValidatorForm} from 'react-material-ui-form-validator';
import SizePicker from '../../../common/components/settings/SizePicker';
import AppColorPicker from '../../../common/components/settings/AppColorPicker';
import Scale from '../../../common/components/Scale';

export default function WelcomeStyleTab(props) {
	const model = props.model;
	
	return (
		<Grid container className={'preview-style-tab welcome-style-tab'} spacing={2}>
			<Grid size={3}>
				<Paper className={'side'}>
					<ValidatorForm noValidate autoComplete='off' onSubmit={props.onSubmit}>
						<SettingsFormControl>
							<AppColorPicker
								variant='outlined'
								label='Background Color'
								value={model.screens.welcome.bgColor}
								TextFieldProps={{value: model.screens.welcome.bgColor}}
								onChange={value => value && props.onChange('screens.welcome.bgColor', value)}
							/>
						</SettingsFormControl>
						
						<SettingsFormControl>
							<MediaPicker
								userId={model.user}
								media={model.screens.welcome.background}
								onSelect={media => props.onChange('screens.welcome.background', media)}
								title={'Select Background'}
							/>
						</SettingsFormControl>
						
						<SettingsFormControl variant={'outlined'}>
							<InputLabel style={{backgroundColor: '#fff'}}>Show Countdown</InputLabel>
							<Select value={model.screens.welcome.countdown.enabled} onChange={e => props.onChange('screens.welcome.countdown.enabled', e.target.value)}>
								<MenuItem value={true}>Yes</MenuItem>
								<MenuItem value={false}>No</MenuItem>
							</Select>
						</SettingsFormControl>
						
						{model.screens.welcome.countdown.enabled && <>
							
							<SettingsFormControl variant={'outlined'}>
								<InputLabel style={{backgroundColor: '#fff'}}>Countdown Position</InputLabel>
								<Select value={model.screens.welcome.countdown.position} onChange={e => props.onChange('screens.welcome.countdown.position', e.target.value)}>
									<MenuItem value={'top'}>Top</MenuItem>
									<MenuItem value={'middle'}>Middle</MenuItem>
									<MenuItem value={'bottom'}>Bottom</MenuItem>
								</Select>
							</SettingsFormControl>
							
							<SizePicker
								label='Countdown Font Size'
								value={model.screens.welcome.countdown.fontSize}
								onChange={v => props.onChange('screens.welcome.countdown.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='CountDown Color'
									value={model.screens.welcome.countdown.color}
									TextFieldProps={{value: model.screens.welcome.countdown.color}}
									onChange={value => value && props.onChange('screens.welcome.countdown.color', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='CountDown Background Color'
									value={model.screens.welcome.countdown.bgColor}
									TextFieldProps={{value: model.screens.welcome.countdown.bgColor}}
									onChange={value => value && props.onChange('screens.welcome.countdown.bgColor', value)}
								/>
							</SettingsFormControl>
						</>
						}
						
						<Box>
							<Button
								variant='contained'
								color='primary'
								disabled={props.saving}
								type={'submit'}
							>
								Save Changes
							</Button>
						</Box>
					</ValidatorForm>
				</Paper>
			</Grid>
			
			<Grid size={9}>
				{model.screens.welcome.enabled
					? <Scale selector={'.quiz-screen'} className={'preview'}>
						<WelcomeScreen
							quiz={{...model, status: 'running'}}
							timeLeft={model.screens.welcome.duration * 1000}
						/>
					</Scale>
					: <Alert severity='warning'>Welcome screen is disabled in Settings</Alert>
				}
			</Grid>
		</Grid>
	)
}

import React from 'react';
import PropTypes from 'prop-types';
import MediaPicker from './MediaPicker';

export default function ImagePicker({className = '', title = 'Select Image', ...props}) {
	return <MediaPicker
		className={className}
		title={title}
		{...props}
		media={props.img ? {mediaType: 'image', image: props.img} : null}
		onSelect={media => props.onSelect(media ? media.image : null)}
		types={['image']}
	/>
}

ImagePicker.propTypes = {
	className: PropTypes.string,
	img: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
	title: PropTypes.string,
	userId: PropTypes.string.isRequired
}

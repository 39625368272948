import React, {useState} from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import {Typography} from '@mui/material';
import SettingsFormControl from '../../../common/components/settings/SettingsFormControl';
import Button from '@mui/material/Button';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import ScoresScreen from '../../../common-libs/quiz/screens/ScoresScreen';
import SizePicker from '../../../common/components/settings/SizePicker';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MediaPicker from '../../../common/components/media/MediaPicker';
import {getDummyScores} from '../../services/QuizService'
import AppColorPicker from '../../../common/components/settings/AppColorPicker';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FinalScreenSoundPicker from '../../components/FinalScreenSoundPicker';
import Scale from '../../../common/components/Scale';

export default function FinalStyleTabScores(props) {
	const model = props.model;
	const settings = model.screens.final.scoresStyles || {};
	const titleSettings = settings.title || {};
	const [firstTabOpen, setFirstTabOpen] = useState(true);
	
	return (
		<Grid container className={'preview-style-tab final-style-tab'} spacing={2}>
			<Grid size={3}>
				<ValidatorForm noValidate autoComplete='off' onSubmit={props.onSubmit}>
					
					<Accordion expanded={firstTabOpen} onChange={() => setFirstTabOpen(!firstTabOpen)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Page Title</Typography>
						</AccordionSummary>
						
						<AccordionDetails className={'accordion-content'}>
							
							<SettingsFormControl>
								<TextValidator
									fullWidth
									required
									variant='outlined'
									label='Screen Title'
									validators={['required']}
									errorMessages={['Screen title is required']}
									value={titleSettings.text}
									onChange={e => props.onChange('screens.final.scoresStyles.title.text', e.target.value)}
								/>
							</SettingsFormControl>
							
							<SizePicker
								value={titleSettings.fontSize}
								min={30}
								max={100}
								onChange={v => props.onChange('screens.final.scoresStyles.title.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Color'
									value={titleSettings.color}
									TextFieldProps={{value: titleSettings.color}}
									onChange={value => value && props.onChange('screens.final.scoresStyles.title.color', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Background Color'
									value={titleSettings.bgColor}
									TextFieldProps={{value: titleSettings.bgColor}}
									onChange={value => value && props.onChange('screens.final.scoresStyles.title.bgColor', value)}
								/>
							</SettingsFormControl>
						</AccordionDetails>
					</Accordion>
					
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Scores</Typography>
						</AccordionSummary>
						
						<AccordionDetails className={'accordion-content no-padding'}>
							
							<SettingsFormControl>
								<FormControlLabel
									control={<Switch color='primary' checked={settings.showPos || false}
									                 onChange={e => props.onChange('screens.final.scoresStyles.showPos', e.target.checked)}/>}
									label='Show Position'
									labelPlacement='end'
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<FormControlLabel
									control={<Switch color='primary' checked={settings.showTeam || false}
									                 onChange={e => props.onChange('screens.final.scoresStyles.showTeam', e.target.checked)}/>}
									label='Show Team'
									labelPlacement='end'
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<FormControlLabel
									control={<Switch color='primary' checked={settings.autoScroll || false}
									                 onChange={e => props.onChange('screens.final.scoresStyles.autoScroll', e.target.checked)}/>}
									label='Auto Scroll'
									labelPlacement='end'
								/>
							</SettingsFormControl>
							
							{settings.autoScroll && <SettingsFormControl>
								<TextValidator
									fullWidth
									validators={['required']}
									errorMessages={['Scroll Delay is required']}
									required
									variant='outlined'
									type='number'
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
									label='Scroll Delay'
									helperText='Time in seconds'
									value={model.screens.final.scoresStyles.scrollDelay}
									onChange={e => props.onChange('screens.final.scoresStyles.scrollDelay', e.target.value)}
								/>
							</SettingsFormControl>}
							
							<SizePicker
								value={settings.fontSize}
								min={15}
								max={45}
								onChange={v => props.onChange('screens.final.scoresStyles.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Username Text Color'
									value={settings.textColor}
									TextFieldProps={{value: settings.textColor}}
									onChange={value => value && props.onChange('screens.final.scoresStyles.textColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Total Scores Color'
									value={settings.totalScoresColor}
									TextFieldProps={{value: settings.totalScoresColor}}
									onChange={value => value && props.onChange('screens.final.scoresStyles.totalScoresColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Line Color'
									value={settings.rowBgColor}
									TextFieldProps={{value: settings.rowBgColor}}
									onChange={value => value && props.onChange('screens.final.scoresStyles.rowBgColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Screen Background Color'
									value={settings.bgColor}
									TextFieldProps={{value: settings.bgColor}}
									onChange={value => value && props.onChange('screens.final.scoresStyles.bgColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<MediaPicker
									userId={model.user}
									title={'Select Background'}
									media={settings.background}
									onSelect={media => props.onChange('screens.final.scoresStyles.background', media)}
								/>
							</SettingsFormControl>
						</AccordionDetails>
					</Accordion>
					
					<Paper className={'save-box'}>
						
						<FinalScreenSoundPicker
							model={model}
							onChange={props.onChange}
						/>
						
						<Button
							variant='contained'
							color='primary'
							disabled={props.saving}
							type='submit'
						>
							Save Changes
						</Button>
					</Paper>
				</ValidatorForm>
			</Grid>
			
			<Grid size={9}>
				<Scale selector={'.quiz-screen'} className={'preview'}>
					<ScoresScreen
						autoScroll={settings.autoScroll}
						scores={getDummyScores(10, 1, 1, 0, model.teams)}
						title={titleSettings.text}
						showPos={settings.showPos}
						showTeam={settings.showTeam}
						showNewScores={false}
						scrollDelay={settings.scrollDelay}
						styles={{
							title: titleSettings,
							page: settings
						}}
					/>
				</Scale>
			</Grid>
		</Grid>
	)
}

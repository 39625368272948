import React from 'react';
import {Routes, Route} from 'react-router-dom';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import LogOut from './pages/LogOut';
import ResetPassword from './pages/ResetPassword';

export default function AuthRoutes() {
	return (
		<Routes>
			<Route path={`signin`} element={<SignIn/>}/>
			<Route path={`forgot-password`} element={<ForgotPassword/>}/>
			<Route path={`logout`} element={<LogOut/>}/>
			<Route path={`reset-password`} element={<ResetPassword/>}/>
		</Routes>
	)
}
import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import BoxLayout from '../../account/components/AccountBox';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Checkbox, FormControlLabel, FormGroup, Paper, Switch} from '@mui/material';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import MainContainer from '../../common/components/MainContainer';

let validatorSet = false;

export default function UserForm({goBackLevel = -1, submitLabel = 'Save Changes', ...props}) {
	const navigate = useNavigate();
	const [data, setData] = useState(props.data);
	const loading = props.loading;
	
	let passValidators = [], passMessages = [];
	if (props.passwordRequired || data.password.length) {
		passValidators = ['required', 'minStringLength:6'];
		passMessages = ['this field is required', 'password should be minimum 6 characters'];
	}
	
	/**
	 * Once form field changed.
	 *
	 * @param field
	 * @param type
	 * @returns {(function(*): void)|*}
	 */
	const setFormData = (field, type = 'input') => event => {
		const newData = {...data};
		objectPath.set(newData, field, type === 'checkbox' ? event.target.checked : event.target.value);
		setData(newData);
	};
	
	/**
	 * Active toggle.
	 *
	 * @param event
	 */
	const setActive = event => {
		const newData = {...data};
		newData.active = event.target.checked;
		setData(newData);
	};
	
	/**
	 * Password match validation.
	 */
	useEffect(() => {
		if (!validatorSet) {
			validatorSet = true;
			ValidatorForm.addValidationRule('isPasswordMatch', value => !data.password || value === data.password);
		}
		return () => {
			validatorSet = false;
			ValidatorForm.removeValidationRule('isPasswordMatch');
		};
	});
	
	return (<ValidatorForm
		noValidate
		onSubmit={() => props.onSubmit(data)}
	>
		<MainContainer>
			<Grid container justifyContent={'center'} className={'user-form'} spacing={3}>
				<Grid size={4}>
					<BoxLayout title={'Details'}>
						<TextValidator
							variant='outlined'
							margin='normal'
							required
							fullWidth
							id='name'
							label='Full Name'
							name='name'
							autoComplete='name'
							autoFocus
							value={data.name}
							validators={['required']}
							errorMessages={['this field is required']}
							onChange={setFormData('name')}
							containerProps={{className: 'fullWidth'}}
						/>
						<TextValidator
							variant='outlined'
							margin='normal'
							required
							fullWidth
							id='email'
							label='Email Address'
							name='email'
							autoComplete='email'
							value={data.email}
							validators={['required', 'isEmail']}
							errorMessages={['this field is required', 'email is not valid']}
							onChange={setFormData('email')}
							containerProps={{className: 'fullWidth'}}
						/>
						
						<FormControl className={'switch'} style={{width: '100%'}}>
							<FormControlLabel
								control={<Switch
									checked={!!data.active}
									onChange={setActive}
									name='active'
									color='primary'
								/>}
								label='Active'
							/>
						</FormControl>
					
					</BoxLayout>
				</Grid>
				
				<Grid size={4}>
					<BoxLayout title={'Permissions'}>
						<FormControl variant={'outlined'} fullWidth className={'select'}>
							<InputLabel required style={{backgroundColor: '#fff'}}>Role</InputLabel>
							<Select required value={data.role} onChange={setFormData('role')}>
								<MenuItem value={'creator'}>Creator</MenuItem>
								<MenuItem value={'editor'}>Editor</MenuItem>
								<MenuItem value={'admin'}>Admin</MenuItem>
							</Select>
						</FormControl>
						
						<FormGroup style={{width: '100%'}}>
							{[
								['Quizzes', 'quiz'], ['Polls', 'poll'], ['Broadcasts', 'broadcast'], ['Video Uploads', 'video']
							].map(([title, type]) => {
								return <FormControlLabel
									key={`p${type}`}
									label={title}
									control={<Checkbox
										disabled={data.role === 'admin'}
										checked={data.role === 'admin' || objectPath.get(data, `permissions.${type}`, false)}
										onChange={setFormData(`permissions.${type}`, 'checkbox')}/>}
								/>
							})}
						</FormGroup>
					</BoxLayout>
				</Grid>
				
				<Grid size={4}>
					<BoxLayout title={'Change Password'}>
						<TextValidator
							variant='outlined'
							margin='normal'
							fullWidth
							name='password'
							label='New Password'
							type='password'
							id='password'
							autoComplete='new-password'
							required={props.passwordRequired}
							value={data.password}
							validators={passValidators}
							errorMessages={passMessages}
							onChange={setFormData('password')}
							containerProps={{className: 'fullWidth'}}
						/>
						<TextValidator
							variant='outlined'
							margin='normal'
							fullWidth
							name='password2'
							label='Confirm Password'
							type='password'
							id='password2'
							value={data.password2}
							autoComplete='confirm-password'
							validators={['isPasswordMatch']}
							errorMessages={['passwords do not match']}
							onChange={setFormData('password2')}
							containerProps={{className: 'fullWidth'}}
						/>
					</BoxLayout>
				</Grid>
				
				<Grid size={12}>
					<Paper>
						<Box p={2} className={'buttons'}>
							<Button
								variant='contained'
								color='secondary'
								disabled={loading}
								onClick={() => navigate(goBackLevel)}
							>Go Back</Button>
							
							<LoadingButton
								type='submit'
								variant='contained'
								color='primary'
								loading={loading}
							>
								{submitLabel}
							</LoadingButton>
						</Box>
					</Paper>
				</Grid>
			
			</Grid>
		</MainContainer>
	
	</ValidatorForm>);
}

UserForm.propTypes = {
	data: PropTypes.object.isRequired,
	goBackLevel: PropTypes.number,
	loading: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	passwordRequired: PropTypes.bool,
	submitLabel: PropTypes.string,
	title: PropTypes.string.isRequired,
}

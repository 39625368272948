import React from 'react';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import AjaxButton from '../../common/buttons/AjaxButton';

export default function ResumeButton({title = 'Resume', disabled = false, ...props}) {
	return <AjaxButton
		ariaLabel={'resume'}
		color={'success'}
		exec={() => api.post(`/apps/broadcast/${props.appId}/resume`)}
		icon={NotStartedIcon}
		title={title}
		disabled={disabled}
		{...props}
	/>
}

ResumeButton.propTypes = {
	title: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onError: PropTypes.func
}

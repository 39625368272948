import React from 'react';
import PropTypes from 'prop-types';
import WaterMark from '../../components/Watermark';
import Media from '../../components/Media';

export default function Screen({className = '', bgColor = '#fff', ...props}) {
	return (
		<div className={`quiz-screen ${className}`} style={{backgroundColor: bgColor}}>
			<Media media={props.mediaBg}/>
			<WaterMark/>
			{props.children}
		</div>
	)
}

Screen.propTypes = {
	className: PropTypes.string,
	bgColor: PropTypes.string,
	mediaBg: PropTypes.object
}

import React, {useState} from 'react';
import {Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PropTypes from 'prop-types';
import ScoresPopup from '../components/ScoresPopup';

export default function ScoresButton(props) {
	
	const app = props.app;
	const [open, setOpen] = useState(false);
	
	return <>
		<Tooltip title={'Leaderboard'}>
			<IconButton color='primary' onClick={() => setOpen(true)} size='small'>
				<EmojiEventsIcon fontSize={'small'}/>
			</IconButton>
		</Tooltip>
		<ScoresPopup app={app} open={open} onClose={() => setOpen(false)}/>
	</>;
}

ScoresButton.propTypes = {
	app: PropTypes.object.isRequired
}
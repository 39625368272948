import React from 'react';
import {Route, Routes} from 'react-router-dom';
import QuizCreate from './QuizCreate';
import QuizEdit from './QuizEdit';
import QuizList from './QuizList';
import ControlRoom from './ControlRoom';
import './style.scss';
import PrivateRoute from "../common/components/PrivateRoute";

export default function QuizRoutes() {
	return (
		<PrivateRoute roles={['admin']} permission={'quiz'} outputType={'children'}>
			<Routes>
				<Route path={`create`} element={<QuizCreate/>}/>
				<Route path={`:quizId/control-room`} element={<ControlRoom/>}/>
				<Route path={`:quizId/*`} element={<QuizEdit/>}/>
				<Route index element={<QuizList/>}/>
			</Routes>
		</PrivateRoute>
	)
}

import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import {Typography} from '@mui/material';
import SettingsFormControl from '../../../common/components/settings/SettingsFormControl';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import ScoresScreen from '../../../common-libs/quiz/screens/ScoresScreen';
import ScoresScreenTeam from '../../../common-libs/quiz/screens/ScoresScreenTeam';
import SizePicker from '../../../common/components/settings/SizePicker';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MediaPicker from '../../../common/components/media/MediaPicker';
import {getDummyScores, getDummyTeamScores} from '../../services/QuizService'
import AppColorPicker from '../../../common/components/settings/AppColorPicker';
import SoundPicker from '../../../common/components/settings/SoundPicker';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FailScreen from '../../../common-libs/quiz/screens/FailScreen';
import Scale from '../../../common/components/Scale';

const soundsSuccess = {
	'applause-crowd': 'Applause Crowd',
	'crowd-arena-cheer': 'Crowd Arena Cheer',
	'crowd-teens-cheer': 'Crowd Teens Cheer',
	'female-crowd-cheer': 'Female Crowd Cheer',
	'firework': 'Firework',
	'firework-rocket': 'Firework Rockets',
	'fireworks-and-crowd': 'Fireworks and Crowd',
	'kids-cheer': 'Kids Cheer',
	'ovation': 'Ovation'
};

const soundsFail = {
	'crowd-boo': 'Crowd Boo',
	'kids-boo': 'Kids Boo',
	'people-boo': 'People Boo',
	'sad-melody': 'Sad Melody',
	'slide-whistle': 'Slide Whistle',
	'trumpet': 'Trumpet'
};

export default function QuestionStyleTab(props) {
	const model = props.model;
	const [currentPreviewScreen, setPreviewScreen] = useState('success');
	const [firstTabOpen, setFirstTabOpen] = useState(true);
	const [blankTabOpen, setBlankTabOpen] = useState(false);
	const screen = model.screens.scores;
	
	useEffect(() => {
		if (blankTabOpen) {
			setPreviewScreen('fail');
		}
	}, [blankTabOpen]);
	
	return (
		<Grid container className={'preview-style-tab scores-style-tab'} spacing={2}>
			<Grid size={3}>
				<ValidatorForm noValidate autoComplete='off' onSubmit={props.onSubmit}>
					
					<Accordion expanded={firstTabOpen} onChange={() => setFirstTabOpen(!firstTabOpen)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Page Title</Typography>
						</AccordionSummary>
						
						<AccordionDetails className={'accordion-content'}>
							
							<SettingsFormControl>
								<TextValidator
									fullWidth
									required
									variant='outlined'
									label='Screen Title'
									validators={['required']}
									errorMessages={['Screen title is required']}
									value={model.screens.scores.title}
									onChange={e => props.onChange('screens.scores.title', e.target.value)}
								/>
							</SettingsFormControl>
							
							<SizePicker
								value={model.screens.scores.screenTitle.fontSize}
								min={30}
								max={100}
								onChange={v => props.onChange('screens.scores.screenTitle.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Color'
									value={model.screens.scores.screenTitle.color}
									TextFieldProps={{value: model.screens.scores.screenTitle.color}}
									onChange={value => value && props.onChange('screens.scores.screenTitle.color', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Background Color'
									value={model.screens.scores.screenTitle.bgColor}
									TextFieldProps={{value: model.screens.scores.screenTitle.bgColor}}
									onChange={value => value && props.onChange('screens.scores.screenTitle.bgColor', value)}
								/>
							</SettingsFormControl>
						</AccordionDetails>
					</Accordion>
					
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Winners List</Typography>
						</AccordionSummary>
						
						<AccordionDetails className={'accordion-content'}>
							
							<SettingsFormControl>
								<FormControlLabel
									control={<Switch color='primary' checked={model.screens.scores.success.showPos || false}
									                 onChange={e => props.onChange('screens.scores.success.showPos', e.target.checked)}/>}
									label='Show Position'
									labelPlacement='end'
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<FormControlLabel
									control={<Switch color='primary' checked={!!model.screens.scores.success.showTeam}
									                 onChange={e => props.onChange('screens.scores.success.showTeam', e.target.checked)}/>}
									label='Show Team'
									labelPlacement='end'
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<FormControlLabel
									control={<Switch color='primary' checked={!!model.screens.scores.success.autoScroll}
									                 onChange={e => props.onChange('screens.scores.success.autoScroll', e.target.checked)}/>}
									label='Auto Scroll'
									labelPlacement='end'
								/>
							</SettingsFormControl>
							
							{model.screens.scores.success.autoScroll && <SettingsFormControl>
								<TextValidator
									fullWidth
									validators={['required']}
									errorMessages={['Scroll Delay is required']}
									required
									variant='outlined'
									type='number'
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
									label='Scroll Delay'
									helperText='Time in seconds'
									value={model.screens.scores.success.scrollDelay}
									onChange={e => props.onChange('screens.scores.success.scrollDelay', +e.target.value)}
								/>
							</SettingsFormControl>}
							
							<SizePicker
								value={model.screens.scores.success.fontSize}
								min={15}
								max={45}
								onChange={v => props.onChange('screens.scores.success.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Username Text Color'
									value={model.screens.scores.success.textColor}
									TextFieldProps={{value: model.screens.scores.success.textColor}}
									onChange={value => value && props.onChange('screens.scores.success.textColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='New Scores Color'
									value={model.screens.scores.success.scoreColor}
									TextFieldProps={{value: model.screens.scores.success.scoreColor}}
									onChange={value => value && props.onChange('screens.scores.success.scoreColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Penalty Scores Color'
									value={model.screens.scores.success.penaltyScoreColor}
									TextFieldProps={{value: model.screens.scores.success.penaltyScoreColor}}
									onChange={value => value && props.onChange('screens.scores.success.penaltyScoreColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Total Scores Color'
									value={model.screens.scores.success.totalScoresColor}
									TextFieldProps={{value: model.screens.scores.success.totalScoresColor}}
									onChange={value => value && props.onChange('screens.scores.success.totalScoresColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Line Color'
									value={model.screens.scores.success.rowBgColor}
									TextFieldProps={{value: model.screens.scores.success.rowBgColor}}
									onChange={value => value && props.onChange('screens.scores.success.rowBgColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Screen Background Color'
									value={model.screens.scores.success.bgColor}
									TextFieldProps={{value: model.screens.scores.success.bgColor}}
									onChange={value => value && props.onChange('screens.scores.success.bgColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<MediaPicker
									userId={model.user}
									title={'Select Background'}
									media={model.screens.scores.success.background}
									onSelect={media => props.onChange('screens.scores.success.background', media)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl variant={'outlined'}>
								<SoundPicker
									sounds={soundsSuccess}
									value={model.screens.scores.success.sound}
									onChange={value => props.onChange('screens.scores.success.sound', value)}
								/>
							</SettingsFormControl>
						
						</AccordionDetails>
					</Accordion>
					
					<Accordion expanded={blankTabOpen} onChange={() => setBlankTabOpen(!blankTabOpen)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Blank Scores Screen</Typography>
						</AccordionSummary>
						
						<AccordionDetails className={'accordion-content'}>
							
							<SettingsFormControl variant={'outlined'}>
								<InputLabel style={{backgroundColor: '#fff'}}>Text Position</InputLabel>
								<Select value={model.screens.scores.fail.position} onChange={e => props.onChange('screens.scores.fail.position', e.target.value)}>
									<MenuItem value={'top'}>Top</MenuItem>
									<MenuItem value={'middle'}>Middle</MenuItem>
									<MenuItem value={'bottom'}>Bottom</MenuItem>
								</Select>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<TextValidator
									fullWidth
									variant='outlined'
									label='Fail Text'
									value={model.screens.scores.failText}
									onChange={e => props.onChange('screens.scores.failText', e.target.value)}
								/>
							</SettingsFormControl>
							
							<SizePicker
								value={model.screens.scores.fail.fontSize}
								min={10}
								onChange={v => props.onChange('screens.scores.fail.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Color'
									value={model.screens.scores.fail.color}
									TextFieldProps={{value: model.screens.scores.fail.color}}
									onChange={value => value && props.onChange('screens.scores.fail.color', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Background Color'
									value={model.screens.scores.fail.textBgColor}
									TextFieldProps={{value: model.screens.scores.fail.textBgColor}}
									onChange={value => value && props.onChange('screens.scores.fail.textBgColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Screen Background Color'
									value={model.screens.scores.fail.bgColor}
									TextFieldProps={{value: model.screens.scores.fail.bgColor}}
									onChange={value => value && props.onChange('screens.scores.fail.bgColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<MediaPicker
									userId={model.user}
									title={'Select Background'}
									media={model.screens.scores.fail.background}
									onSelect={media => props.onChange('screens.scores.fail.background', media)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl variant={'outlined'}>
								<SoundPicker
									sounds={soundsFail}
									value={model.screens.scores.fail.sound}
									onChange={value => props.onChange('screens.scores.fail.sound', value)}
								/>
							</SettingsFormControl>
						
						</AccordionDetails>
					</Accordion>
					
					<Paper className={'save-box'}>
						<Button
							variant='contained'
							color='primary'
							disabled={props.saving}
							type={'submit'}
						>
							Save Changes
						</Button>
					</Paper>
				</ValidatorForm>
			</Grid>
			
			<Grid size={9}>
				<Paper className={'screen-select'}>
					<SettingsFormControl variant={'outlined'}>
						<InputLabel style={{backgroundColor: '#fff'}}>Preview Screen</InputLabel>
						<Select value={currentPreviewScreen} onChange={e => setPreviewScreen(e.target.value)}>
							<MenuItem value={'success'}>Winners List (Some people answered correctly)</MenuItem>
							<MenuItem value={'fail'}>Blank Scores Screen (No one answered correctly)</MenuItem>
							<MenuItem value={'teams'}>Team's Scores</MenuItem>
						</Select>
					</SettingsFormControl>
				</Paper>
				
				<Scale selector={'.quiz-screen'} className={'preview'}>
					{currentPreviewScreen === 'success' && (
						<ScoresScreen
							autoScroll={screen.success.autoScroll}
							scores={getDummyScores(model.maxScore, model.scoreStep, model.minScore, model.penaltyScore, model.teams)}
							title={screen.title}
							showPos={screen.success.showPos}
							showTeam={screen.success.showTeam}
							scrollDelay={screen.success.scrollDelay}
							styles={{
								title: screen.screenTitle,
								page: screen.success
							}}
						/>
					)}
					
					{currentPreviewScreen === 'fail' && <FailScreen model={model}/>}
					
					{currentPreviewScreen === 'teams' && (
						<ScoresScreenTeam
							teams={model.teams}
							scores={getDummyTeamScores(model.maxScore, model.scoreStep, model.minScore, model.penaltyScore, model.teams)}
							title={screen.title}
							styles={{
								title: screen.screenTitle,
								page: screen.success
							}}
						/>
					)}
				</Scale>
			</Grid>
		</Grid>
	)
}

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';

export default function ScreenProgress({startTime, endTime}) {
	
	const [progress, setProgress] = useState(0);
	
	useEffect(() => {
		
		const int = setInterval(function () {
			const p = 100 - 100 * (endTime - Date.now()) / (endTime - startTime);
			setProgress(Math.min(p, 100));
		}, 100);
		
		return () => {
			clearInterval(int);
		}
	}, [startTime, endTime]);
	
	return <LinearProgress
		variant={'determinate'}
		value={progress}
		className={'progress'}
	/>
	
}

ScreenProgress.propTypes = {
	startTime: PropTypes.number.isRequired,
	endTime: PropTypes.number.isRequired
}

import React from 'react';
import MainContainer from '../../common/components/MainContainer';
import Grid from '@mui/material/Grid2';
import GeneralBox from '../boxes/GeneralBox';
import WelcomeBox from '../boxes/WelcomeBox';
import ScoresBox from '../boxes/ScoresBox';
import {ValidatorForm} from 'react-material-ui-form-validator';
import ActionBar from '../components/ActionBar';
import FinalScreenBox from '../boxes/FinalScreenBox';
import PosterBox from '../../common/components/settings/PosterBox';
import ContactFormBox from '../boxes/ContactFormBox';
import LiveStreamsSettingsBox from '../../common/components/settings/livestream/LiveStreamsSettingsBox';

export default function SettingsTab(props) {
	const model = props.model;
	
	return <ValidatorForm noValidate autoComplete='off' onSubmit={props.onSubmit} style={{marginTop: 0}}>
		<ActionBar saving={props.saving} type={'submit'}/>
		<MainContainer>
			<Grid container direction='row' spacing={3}>
				<Grid size={6}>
					<Grid container direction='column' spacing={3}>
						<Grid><GeneralBox model={model} channels={props.channels} onChange={props.onChange} onError={props.onError}/></Grid>
						<Grid>
							<LiveStreamsSettingsBox
								channels={props.channels}
								disabled={['running', 'starting', 'paused'].includes(model.status)}
								livestreams={model.livestreams}
								onChange={livestreams => props.onChange('livestreams', livestreams)}
								onError={props.onError}
							/>
						</Grid>
						<Grid><PosterBox model={model} onChange={props.onChange}/></Grid>
						<Grid><ContactFormBox model={model} onChange={props.onChange}/></Grid>
					</Grid>
				</Grid>
				<Grid size={6}>
					<Grid container direction='column' spacing={3}>
						<Grid><WelcomeBox model={model} onChange={props.onChange}/></Grid>
						<Grid><ScoresBox model={model} onChange={props.onChange}/></Grid>
						<Grid><FinalScreenBox model={model} onChange={props.onChange}/></Grid>
					</Grid>
				</Grid>
			</Grid>
		</MainContainer>
	</ValidatorForm>
}

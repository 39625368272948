import React from 'react';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {ListItemButton, ListItemText} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import SocialIcon from '../SocialIcon';
import Divider from '@mui/material/Divider';
import {getLiveStreamURL} from '../../utils/livestream';

export default function LivestreamsTableCell(props) {
	const livestreams = props.livestreams;
	
	return (
		<TableCell className={'livestreams-table-cell'}>
			{(livestreams && livestreams.length) && (
				<List>
					{livestreams.map((l, i) => <LineItem key={`ls${i}`} livestream={l} divider={i < livestreams.length - 1}/>)}
				</List>
			)}
		</TableCell>
	)
}

LivestreamsTableCell.propTypes = {
	livestreams: PropTypes.array
}

function LineItem(props) {
	const l = props.livestream;
	
	if (!l || !l.channel) {
		return <></>;
	}
	
	const channel = l.channel;
	return <>
		<ListItem disablePadding>
			<ListItemButton component={'a'} href={getLiveStreamURL(l)} target={'_blank'}>
				<ListItemAvatar>
					<Avatar alt={channel.title} sx={{bgcolor: 'rgba(0,0,0,0)', width: '24px', height: '24px'}}>
						<SocialIcon type={channel.socialType} size={'1x'}/>
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={l.title || channel.title} secondary={l.title ? channel.title : null}/>
			</ListItemButton>
		</ListItem>
		{props.divider && <Divider variant='inset' component='li'/>}
	</>
}

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Paper} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export default function Welcome(props) {
	const user = props.user;
	return (
		<Paper className={'paper'}>
			<Typography variant={'h6'} mb={2}>Welcome, {user.name}</Typography>
			<Button component={Link} to={'/account/'}>Update Profile</Button>
			<Button component={Link} to={'/account/'}>Change Password</Button>
		</Paper>
	);
}

Welcome.propTypes = {
	user: PropTypes.object.isRequired
}
import React, {useEffect, useState} from 'react';
import api from '../common/ApiRequest';
import PagePreloader from '../common/components/PagePreloader';
import Notification from '../common/components/Notification';
import MainContainer from '../common/components/MainContainer';
import Grid from '@mui/material/Grid2';
import {Box, Paper, TableCell, Typography} from '@mui/material';
import Status from './components/Status';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ClipboardButton from '../common/buttons/ClipboardButton';
import StartStopButton from './buttons/StartStopButton';
import Scale from '../common/components/Scale';
import StreamLink from '../common/components/StreamLink';
import io from 'socket.io-client';
import TogglePreviewButton from '../common/buttons/TogglePreviewButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import SettingsFormControl from '../common/components/settings/SettingsFormControl';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import PollTabs from './components/PollTabs';
import AppBar from '@mui/material/AppBar';

export default function PollControlRoom() {
	
	const navigate = useNavigate();
	const params = useParams();
	const [message, setMessage] = useState(null);
	const [model, setModel] = useState(null);
	const [loading, setLoading] = useState(true);
	const [starting, setStarting] = useState(false);
	const [iframeV, setIframeV] = useState(1);
	const [iframeVisible, setIframeVisible] = useState(true);
	const [running, setRunning] = useState(false);
	const [currentPoll, setCurrentPoll] = useState(null);
	const [stats, setStats] = useState(null);
	
	const modelId = model ? model._id : null;
	const serverUrl = modelId ? model.serverUrl : null;
	
	const onStatusChange = type => {
		if (type === 'start') {
			setStarting(() => true);
		}
		setLoading(() => true);
	}
	
	const onGameMessage = question => {
		if (question && question._id) {
			setCurrentPoll(() => question._id.toString());
			
			let total = 0;
			let options = question.options;
			let items = [];
			
			options.forEach(o => total += o.votes);
			
			if (total > 0) {
				let percentageLeft = 100;
				for (let i = 0; i < options.length; i++) {
					let o = options[i];
					let percent = Math.round(o.votes / total * 100);
					items.push({
						text: o.text,
						votes: o.votes,
						percent: i < options.length - 1 ? percent : percentageLeft
					});
					percentageLeft -= percent;
				}
				items.sort((a, b) => b.votes - a.votes);
			}
			
			setStats({total, items});
		} else {
			setCurrentPoll(() => null);
		}
	}
	
	const onQuestionChange = e => {
		setCurrentPoll(() => e.target.value);
		setStats(() => null);
		(async () => await sendCurrentQuestionId(e.target.value))();
	}
	
	/**
	 * Wait till poll is started.
	 */
	useEffect(() => {
		if (starting) {
			const interval = setInterval(() => setLoading(true), 3000);
			return () => clearInterval(interval);
		}
	}, [starting]);
	
	/**
	 * Connect to the game server when poll is started.
	 */
	useEffect(() => {
		let client;
		if (running && modelId && serverUrl) {
			
			// Flag as started.
			setStarting(() => false);
			
			// Refresh iframe.
			setIframeV(prev => prev + 1);
			
			// Connect to the server via socket.
			client = io(`${serverUrl}/${modelId}`, {
				transports: ['websocket']
			});
			client.on('cmd', cmd => onGameMessage(cmd));
			client.on('disconnect', () => {
				setRunning(() => false);
				setLoading(() => true);
			});
		} else {
			setCurrentPoll(() => null);
		}
		
		return () => {
			if (client) {
				try {
					client.disconnect()
				} catch (e) {
					console.log(e);
				}
			}
		};
	}, [running, modelId, serverUrl])
	
	/**
	 * Reload poll data on demand.
	 */
	useEffect(() => {
		if (loading) {
			setLoading(() => false);
			(async () => {
				try {
					const poll = await api.get(`/apps/poll/${params.pollId}`);
					setModel(() => poll);
					setStarting(() => poll.status === 'starting');
					setRunning(() => !!poll.serverUrl && ['running'].includes(poll.status));
				} catch (e) {
					setMessage(() => e.toString());
				}
			})();
		}
	}, [loading, params.pollId]);
	
	/**
	 * Send command to the server to vote for the specific question.
	 */
	const sendCurrentQuestionId = async questionId => {
		if (running) {
			try {
				questionId = questionId || null;
				await api.post(`/apps/poll/${model._id}/question`, {questionId});
			} catch (e) {
				setMessage(e.toString());
			}
		}
	};
	
	return <div className={'app-control-room poll'}>
		{!model ?
			<PagePreloader error={message}/> :
			<>
				<Notification
					message={message}
					severity={'error'}
					onClear={() => setMessage(null)}
				/>
				
				<MainContainer>
					<AppBar position={'sticky'} color={'default'} className={'app-bar'}>
						<PollTabs onClick={path => navigate(`/poll/${params.pollId}/${path}`)} selected={'control-room'} mode={'edit'}/>
					</AppBar>
					
					<Grid container spacing={2}>
						<Grid size={3}>
							
							<Box mb={1}>
								<Status model={model}/>
							</Box>
							
							<Paper className='sidebar'>
								<Typography variant='subtitle2' color={'primary'} className={'title'}>Poll</Typography>
								<Box mb={2.5}>
									<Link to={`/poll/${model._id}`}>
										<Typography variant='body2'>{model.name}</Typography>
									</Link>
								</Box>
								
								<Typography variant='subtitle2' color={'primary'} className={`title icon-title`}>
									Source URL
									<ClipboardButton value={model.clientUrl}/>
								</Typography>
								
								<Box mb={2.5}>
									<a href={model.clientUrl} target={'_blank'} rel='noreferrer' className={'ellipsis'}>
										<Typography variant='body2'>{model.clientUrl}</Typography>
									</a>
								</Box>
							</Paper>
							
							{(model.livestreams && model.livestreams.length > 0) && (
								<Paper className='sidebar'>
									<Typography variant='subtitle2' color={'primary'} className={`title icon-title`} mb={1.5}>
										Live Streams
									</Typography>
									{model.livestreams && model.livestreams.map((livestream, i) => <div key={`ls-${i}`}>
										<Box mb={1}>
											<StreamLink livestream={livestream}/>
										</Box>
									</div>)}
								</Paper>
							)}
							
							{stats && <Paper className='sidebar'>
									<Typography variant='subtitle2' color={'primary'} className={'title'}>Statistics</Typography>
									<Box mb={1.5} mt={0.5}>
										<Typography variant='body2'><strong>{stats.total}</strong> people voted</Typography>
									</Box>
									<TableContainer component={Paper}>
										<Table size='small'>
											<TableHead>
												<TableRow>
													<TableCell>Option</TableCell>
													<TableCell align='right'>Votes</TableCell>
													<TableCell align='right'>Percent</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{stats.items.map(item => (
													<TableRow key={item.text}>
														<TableCell>{item.text}</TableCell>
														<TableCell align='right'>{item.votes}</TableCell>
														<TableCell align='right'>{item.percent}%</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							}
						
						</Grid>
						<Grid size={9}>
							<Paper className={'paper'}>
								<TogglePreviewButton
									visible={iframeVisible}
									onToggle={visible => setIframeVisible(visible)}
								/>
								
								<StartStopButton
									app={model}
									onError={e => setMessage(e.toString())}
									onChange={onStatusChange}
								/>
								
								<SettingsFormControl variant={'outlined'} className={'poll-list'}>
									<InputLabel style={{backgroundColor: '#fff'}}>Current Poll</InputLabel>
									<Select value={currentPoll || false} onChange={onQuestionChange} disabled={model.status !== 'running'}>
										<MenuItem value={false}>None</MenuItem>
										{model.questions.map(q => <MenuItem value={q._id} key={`op${q._id}`}>{q.text}</MenuItem>)}
									</Select>
								</SettingsFormControl>
							</Paper>
							
							<Box mt={2}>
								{iframeVisible && (
									<Scale selector={'iframe'} className={'preview'}>
										<iframe
											title={'Poll View'}
											className={`iframe`}
											src={`${model.clientUrl}?v=${iframeV}`}
										></iframe>
									</Scale>
								)}
							</Box>
						</Grid>
					</Grid>
				</MainContainer>
			</>
		}
	</div>;
}

import React, {useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import HeightIcon from '@mui/icons-material/Height';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import api from '../../common/ApiRequest';
import Notification from '../../common/components/Notification';

export default function QuestionPreview(props) {
	const question = props.question;
	const [loading, setLoading] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [error, setError] = useState(null);
	
	/**
	 * Load question details.
	 *
	 * @returns {Promise<void>}
	 */
	const load = async () => {
		setLoading(true);
		try {
			const q = await api.get(`/apps/quiz/question/${question._id}`);
			setLoading(() => false);
			props.onLoad(q);
		} catch (e) {
			setError(() => e.toString());
			setLoading(() => false);
		}
	}
	
	/**
	 * Remove question.
	 *
	 * @returns {Promise<void>}
	 */
	const remove = async () => {
		setRemoving(true);
		try {
			await api.delete(`/apps/quiz/question/${question._id}`);
			props.onRemove && props.onRemove();
		} catch (e) {
			setError(() => e.toString());
			setRemoving(() => false);
		}
	}
	
	return (
		<Paper className={'quiz-question-preview'}>
			{error && <Notification severity={'error'} onClear={() => setError(null)} message={error}/>}
			
			<div className={`drag`} title={'Drag to change the order'} ref={props.dragRef} {...props.dragListeners}>
				<HeightIcon color={'primary'}/>
			</div>
			<div className={'number'}>
				#{question.position}
			</div>
			<Typography className={'question-text'}>{question.text}</Typography>
			
			<IconButton
				aria-label='move up'
				onClick={() => props.onMoveUp()}
				title={'Move Up'}
				disabled={loading || removing}
				size='large'>
				<ArrowUpwardIcon/>
			</IconButton>
			
			<IconButton
				aria-label='move down'
				onClick={() => props.onMoveDown()}
				title={'Move Down'}
				disabled={loading || removing}
				size='large'>
				<ArrowDownwardIcon/>
			</IconButton>
			
			{!loading && <IconButton
				aria-label='edit'
				color='primary'
				disabled={removing}
				onClick={load}
				title={'Edit'}
				size='large'>
				<EditIcon/>
			</IconButton>}
			{loading && <CircularProgress color={'primary'} size={16} className={'progress'}/>}
			
			{!removing && <IconButton
				aria-label='delete'
				color='secondary'
				disabled={loading}
				onClick={remove}
				title={'Remove'}
				size='large'>
				<DeleteIcon/>
			</IconButton>}
			{removing && <CircularProgress color={'secondary'} size={16} className={'progress'}/>}
		</Paper>
	);
}

QuestionPreview.propTypes = {
	question: PropTypes.object.isRequired,
	onLoad: PropTypes.func.isRequired,
	onMoveUp: PropTypes.func.isRequired,
	onMoveDown: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
}
import React from 'react';
import Grid from '@mui/material/Grid2';
import SettingsFormControl from '../SettingsFormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon} from '@mui/material';
import SocialIcon from '../../SocialIcon';
import YoutubeLiveStreamSelect from '../../../../youtube/components/YoutubeLiveStreamSelect';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

export default function LiveStreamPicker({disabled = false, deleteBtn = true, ...props}) {
	const livestream = props.livestream;
	
	let channel = null;
	if (livestream) {
		if (typeof livestream.channel === 'object') {
			channel = livestream.channel;
		} else {
			channel = props.channels.find(c => c._id === livestream.channel);
		}
	}
	const showLivestreamDropdown = !channel || channel.socialType === 'youtube';
	
	const onChange = e => {
		const channel = props.channels.find(c => c._id === e.target.value);
		props.onChange({channel})
	}
	
	return (
		<Grid container spacing={1} className={'livestream-settings-line'}>
			
			<Grid size={showLivestreamDropdown ? 6 : (deleteBtn ? 11 : 12)}>
				<SettingsFormControl variant='outlined'>
					<InputLabel required style={{backgroundColor: '#fff'}}>Channel</InputLabel>
					<Select
						required
						disabled={props.disabled}
						value={channel ? channel._id || '' : ''}
						onChange={onChange}
					>
						{props.channels.map(c => <MenuItem key={`c${c._id}`} value={c._id}>
							<ListItemIcon className={'social-type'}>
								<SocialIcon type={c.socialType}/>
							</ListItemIcon>
							{c.title}
						</MenuItem>)}
					</Select>
				</SettingsFormControl>
			</Grid>
			
			{showLivestreamDropdown && <Grid size={deleteBtn ? 5 : 6}>
				<SettingsFormControl>
					<YoutubeLiveStreamSelect
						value={livestream}
						disabled={props.disabled}
						onChange={(e, newValue) => props.onChange(newValue)}
						onError={props.onError}
					/>
				</SettingsFormControl>
			</Grid>}
			
			{deleteBtn && <Grid size={1}>
				<IconButton aria-label='delete' onClick={props.onDelete} disabled={props.disabled}>
					<DeleteIcon fontSize='inherit' color={props.disabled ? 'disabled' : 'error'}/>
				</IconButton>
			</Grid>}
		
		</Grid>
	);
}

LiveStreamPicker.propTypes = {
	channels: PropTypes.array,
	deleteBtn: PropTypes.bool,
	disabled: PropTypes.bool,
	livestream: PropTypes.any,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	onError: PropTypes.func
};

import React, {useEffect, useState} from 'react';
import QuestionForm from '../components/QuestionForm';
import {createEmptyQuestion} from '../services/QuestionService';
import MainContainer from '../../common/components/MainContainer';
import ActionBar from '../components/ActionBar';
import {Box, Button, LinearProgress} from '@mui/material';
import ExportQuestions from '../components/ExportQuestions';
import ImportQuestions from '../components/ImportQuestions';
import api from '../../common/ApiRequest';
import sleep from '../../common-libs/utils/sleep';
import Notification from '../../common/components/Notification';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import SearchBar from '../../common/components/SearchBar';
import QuestionList from '../components/QuestionList';

export default function QuestionsTab(props) {
	const quiz = props.model;
	
	const [loading, setLoading] = useState(false);
	const [questions, setQuestions] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [searchParams, setSearchParams] = useState({
		key: '',
		page: 1
	});
	
	const [addingNew, setAddingNew] = useState(false);
	const [questionInEdit, setQuestionInEdit] = useState(null);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	
	/**
	 * Load questions from the server.
	 */
	const loadData = () => {
		setLoading(true);
		api
			.get(`/apps/quiz/${quiz._id}/question`, searchParams)
			.then(result => {
				setQuestions(() => result.data);
				setPagination(() => result.pagination);
			})
			.catch(e => setError(() => e.toString()))
			.finally(() => setLoading(() => false));
	}
	
	/**
	 * Load data from the server.
	 */
	useEffect(loadData, [searchParams, quiz._id]);
	
	/**
	 * Add new quiz question.
	 *
	 * @param newQuestion
	 * @returns {Promise<void>}
	 */
	const addQuestion = async newQuestion => {
		setSaving(() => true);
		setError(() => null);
		await sleep(500);
		
		try {
			await api.put(`/apps/quiz/${quiz._id}/question`, newQuestion);
			setAddingNew(() => false);
			loadData();
		} catch (e) {
			setError(() => e.toString());
		} finally {
			setSaving(() => false);
		}
	}
	
	/**
	 * Update question.
	 *
	 * @param newData
	 * @returns {Promise<void>}
	 */
	const updateQuestion = async newData => {
		setSaving(() => true);
		setError(() => null);
		await sleep(500);
		
		try {
			await api.post(`/apps/quiz/question/${questionInEdit._id}`, newData);
			setQuestionInEdit(() => null);
			loadData();
		} catch (e) {
			setError(() => e.toString());
		} finally {
			setSaving(() => false);
		}
	}
	
	return (
		<div className={'questions-tab'}>
			{error && <Notification severity={'error'} onClear={() => setError(null)} message={error}/>}
			
			<ActionBar showSaveBtn={false}>
				<ImportQuestions model={quiz} onComplete={loadData}/>
				<ExportQuestions quizId={quiz._id}/>
				
				<Button
					variant={'contained'}
					color={'primary'}
					className={'btn'}
					onClick={() => setAddingNew(true)}
				>
					Add Question
				</Button>
			</ActionBar>
			
			<MainContainer>
				<SearchBar placeholder={'Enter a keyword and press Enter'} onSubmit={key => setSearchParams({key, page: 1})}/>
				
				<Box sx={{width: '100%'}} style={{visibility: loading ? 'visible' : 'hidden'}}> <LinearProgress/> </Box>
				
				{addingNew && <QuestionForm
					title={'Add Question'}
					question={createEmptyQuestion()}
					saving={saving}
					btnTitle={'Add'}
					onCancel={() => setAddingNew(false)}
					onSubmit={q => addQuestion(q)}
				/>}
				
				{questionInEdit && <QuestionForm
					title={'Edit Question'}
					question={questionInEdit}
					saving={saving}
					btnTitle={'Save'}
					onCancel={() => setQuestionInEdit(null)}
					onSubmit={q => updateQuestion(q)}
				/>}
				
				{(questions && questions.length > 0) && <QuestionList
					questions={questions}
					onChange={loadData}
					onError={e => setError(e)}
					onLoad={q => setQuestionInEdit(q)}
					onRemove={loadData}
				/>}
				
				{(pagination && pagination.totalPages > 1) && <Pagination
					className={'pagination'}
					size={'large'}
					color={'primary'}
					page={pagination.page}
					count={pagination.totalPages}
					renderItem={(item) => (
						<PaginationItem
							{...item}
							onClick={() => setSearchParams({...searchParams, page: item.page})}
						/>
					)}
				/>}
			
			</MainContainer>
		</div>
	);
}

QuestionsTab.propTypes = {
	model: PropTypes.object.isRequired
}

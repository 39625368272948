import React from 'react';
import api from '../../common/ApiRequest';
import PagePreloader from '../../common/components/PagePreloader';
import withLocation from '../../common/providers/withLocation';

class YoutubeCallback extends React.Component {
	
	constructor(props, context) {
		super(props, context);
		this.state = {
			error: null
		};
	}
	
	componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		api.post('/youtube/finalize', {
			code: query.get('code')
		})
			.then(() => this.props.navigate('/channel/'))
			.catch(e => this.setState({error: e.toString()}));
	}
	
	render() {
		return <PagePreloader error={this.state.error}/>
	}
}

export default withLocation(YoutubeCallback);
import React from 'react';
import PropTypes from 'prop-types';
import {Autocomplete, Chip, TextField} from '@mui/material';

export default function ChipInput({label = 'Tags', options = [], ...props}) {
	return (
		<Autocomplete
			multiple
			freeSolo
			options={[]}
			defaultValue={[]}
			disableClearable={true}
			value={options}
			required={props.required}
			onChange={(e, values) => props.onChange && props.onChange(values)}
			renderInput={params => (
				<TextField
					size={'small'}
					{...params}
					{...props.inputProps}
					label={label}
					required={props.required}
					error={!!props.errorMsg}
					helperText={props.errorMsg || props.inputProps.helperText}
				/>
			)}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => {
					const {key, ...props} = getTagProps({index});
					return <Chip
						variant={'outlined'}
						label={option}
						size={'small'}
						key={key}
						{...props}
					/>
				})
			}
		/>
	)
}

ChipInput.propTypes = {
	errorMsg: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	required: PropTypes.bool
}

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, DialogActions, DialogContent, ImageList, ImageListItem, ImageListItemBar, useTheme} from '@mui/material';
import UploadButton from '../../buttons/UploadButton';
import api from '../../ApiRequest';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import sleep from '../../../common-libs/utils/sleep';
import {LoadingButton} from '@mui/lab';

export default function ImageDialogBody({userId, ...props}) {
	const theme = useTheme();
	const [uploading, setUploading] = useState(false);
	const [selected, setSelected] = useState(props.selected);
	const [search, setSearch] = useState({
		page: 1,
		loading: true,
		items: [],
		totalPages: 1
	});
	
	/**
	 * Load videos.
	 */
	useEffect(() => {
		if (!search.loading) {
			return;
		}
		
		(async () => {
			await sleep(500);
			
			let endpoint, data;
			if (userId) {
				endpoint = '/image/';
				data = {userId: userId, page: search.page};
			} else {
				endpoint = '/image/standard';
				data = {page: search.page};
			}
			
			api.get(endpoint, data)
				.then(result => {
					const prevItems = search.page === 1 ? [] : search.items;
					setSearch({
						items: [...prevItems, ...result.data],
						page: result.pagination.page,
						totalPages: result.pagination.totalPages,
						loading: false
					});
				})
				.catch(e => {
					console.log(e);
					setSearch(prev => ({
						...prev,
						loading: false
					}));
				});
		})();
	}, [search, userId]);
	
	const loadMore = () => {
		setSearch(prev => ({...prev, page: prev.page + 1, loading: true}));
	}
	
	const onClose = () => {
		props.onClose && props.onClose();
	}
	
	const onError = e => {
		props.onError && props.onError(e);
	}
	
	const onSelect = item => {
		props.onSelect && props.onSelect(item);
	}
	
	const onStart = () => {
		setUploading(() => true);
	}
	
	return (
		<>
			<DialogContent className={'image-dialog-content'} dividers>
				
				<ImageList cols={4} className={'image-list'}>
					{search.items.map((img, i) => <ImageListItem
							key={`img${i}`}
							onClick={() => setSelected(img)}
							className={'image-list-item'}
						>
							<img
								alt={''}
								src={img.thumbSrc}
								loading={'lazy'}
							/>
							{(selected && selected._id === img._id) && <ImageListItemBar className={'image-bar'} actionIcon={<CheckCircleOutlineIcon style={{color: theme.palette.success.light}}/>}/>}
						</ImageListItem>
					)}
				</ImageList>
				
				{(search.totalPages > search.page || search.loading) && <LoadingButton className={'load-more-btn'} onClick={loadMore} loading={search.loading}>Load More</LoadingButton>}
			
			</DialogContent>
			<DialogActions className={'image-dialog-actions'}>
				{userId && <UploadButton
					onStart={onStart}
					onComplete={onSelect}
					onError={onError}
					title={'Upload Image'}
					className={'upload-btn'}
					uploadData={{userId}}
				/>}
				<Button color={'secondary'} variant={'contained'} disabled={uploading} onClick={onClose}>Cancel</Button>
				<Button color={'primary'} variant={'contained'} disabled={!selected || uploading} onClick={() => onSelect(selected)}>Select this image</Button>
			</DialogActions>
		</>);
}

ImageDialogBody.propTypes = {
	onClose: PropTypes.func,
	onError: PropTypes.func,
	onSelect: PropTypes.func,
	open: PropTypes.bool,
	selected: PropTypes.object,
	userId: PropTypes.string
};

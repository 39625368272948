import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import AjaxButton from '../../common/buttons/AjaxButton';

export default function ToggleScoresScreenButton({currentScreen = 'user', disabled = false, ...props}) {
	const execute = () => api.post(`/apps/quiz/${props.quizId}/cmd`, {
		cmd: 'score-screen',
		screenType: currentScreen === 'team' ? 'user' : 'team'
	});
	
	return <AjaxButton
		ariaLabel={'toggle-screen'}
		color={'primary'}
		progressColor={'primary'}
		exec={execute}
		disabled={disabled}
		icon={currentScreen === 'team' ? PersonIcon : PeopleAltIcon}
		title={currentScreen === 'team' ? 'Show user scores' : 'Show team scores'}
		{...props}
	/>
}

ToggleScoresScreenButton.propTypes = {
	currentScreen: PropTypes.string,
	disabled: PropTypes.bool,
	quizId: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onError: PropTypes.func
}

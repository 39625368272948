import React from 'react';
import Alert from "@mui/material/Alert";

import TimelapseIcon from '@mui/icons-material/Timelapse';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import PauseIcon from '@mui/icons-material/Pause';

export default function Status(props) {
	
	const app = props.model;
	app.checkStatus = app.checkStatus || {code: 'error', msg: 'Re-save quiz'};
	
	let severity;
	let msg;
	let variant = 'standard';
	let icon;
	
	const iconMapping = {
		ok: <DoneAllIcon fontSize="inherit"/>,
		running: <TimelapseIcon fontSize="inherit"/>,
		paused: <PauseIcon fontSize="inherit"/>,
		complete: <DoneOutlineIcon fontSize="inherit"/>
	};
	
	const severityMapping = {
		ok: 'success',
		complete: 'success',
		running: 'info',
		paused: 'info',
		starting: 'info',
		warning: 'warning',
		error: 'error'
	};
	
	if (app.status === 'idle') {
		severity = severityMapping[app.checkStatus.code];
		msg = app.checkStatus.code === 'ok' ? 'Ready to start' : app.checkStatus.msg;
		variant = app.checkStatus.code === 'ok' ? 'outlined' : 'standard';
		icon = iconMapping[app.checkStatus.code];
	} else {
		severity = severityMapping[app.status];
		icon = iconMapping[app.status];
		msg = app.status.toUpperCase();
	}
	
	icon = icon ? icon : null;
	severity = severity ? severity : 'error';
	
	return (
		<Alert
			severity={severity}
			variant={variant}
			icon={icon}
		>{msg}</Alert>
	);
}
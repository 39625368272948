import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {CircularProgress, Tooltip} from '@mui/material';
import PropTypes from 'prop-types';

export default function AjaxButton(
	{
		ariaLabel = '',
		className = '',
		color = 'primary',
		progressColor = 'secondary',
		progressMargin = '16px',
		progressSize = 27,
		size = 'large',
		...props
	}) {
	
	const [loading, setLoading] = useState(false);
	const Icon = props.icon;
	
	const onClick = () => {
		setLoading(true);
		props.exec
			.call()
			.then(data => props.onChange && props.onChange(data))
			.catch(e => props.onError && props.onError(e))
			.finally(() => {
				setTimeout(() => setLoading(false), 500);
			});
	};
	
	return <div className={className}>
		{loading
			? <CircularProgress
				color={progressColor}
				size={progressSize}
				style={{display: 'flex', alignItems: 'center', margin: `0 ${progressMargin}`}}
			/>
			: <Tooltip title={props.title}>
                <span>
                    <IconButton
	                    aria-label={ariaLabel}
	                    color={color}
	                    disabled={props.disabled}
	                    onClick={onClick}
	                    size={size}>
                        <Icon fontSize={size}/>
                    </IconButton>
                </span>
			</Tooltip>
		}
	</div>;
}

AjaxButton.propTypes = {
	ariaLabel: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	exec: PropTypes.func.isRequired,
	icon: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	onError: PropTypes.func,
	progressColor: PropTypes.string,
	progressSize: PropTypes.number,
	size: PropTypes.string,
	title: PropTypes.string,
}

import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

export default function Notification({severity = 'success', verticalPos = 'bottom', horizontalPos = 'center', ...props}) {
	
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		
		if (props.onClear) {
			props.onClear();
		}
	};
	
	return (
		<Snackbar
			open={!!props.message}
			onClose={handleClose}
			autoHideDuration={5000}
			anchorOrigin={{vertical: verticalPos, horizontal: horizontalPos}}
		>
			<Alert onClose={() => handleClose()} severity={severity} variant={'filled'}>
				{props.message}
			</Alert>
		</Snackbar>
	)
}

Notification.propTypes = {
	message: PropTypes.string,
	onClear: PropTypes.func,
	severity: PropTypes.string,
	verticalPos: PropTypes.string,
	horizontalPos: PropTypes.string
}

import React from 'react';
import {Route, Routes} from 'react-router-dom';
import PollCreate from './PollCreate';
import PollEdit from './PollEdit';
import PollList from './PollList';
import PollControlRoom from './PollControlRoom';
import './style.scss';
import PrivateRoute from '../common/components/PrivateRoute';

export default function PollRoutes() {
	return (
		<PrivateRoute roles={['admin']} permission={'poll'} outputType={'children'}>
			<Routes>
				<Route path={`create`} element={<PollCreate/>}/>
				<Route path={`:pollId/control-room`} element={<PollControlRoom/>}/>
				<Route path={`:pollId/*`} element={<PollEdit/>}/>
				<Route index element={<PollList/>}/>
			</Routes>
		</PrivateRoute>
	)
}
import React from 'react';
import IconButton from '@mui/material/IconButton';
import {Link} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import {Tooltip} from '@mui/material';
import PropTypes from 'prop-types';

export default function EditButton(props) {
	return (
		<Tooltip title={'Edit'}>
			<IconButton
				aria-label='edit'
				color='primary'
				component={Link}
				to={`/quiz/${props.app._id}`}
				size='small'>
				<EditIcon fontSize={'small'}/>
			</IconButton>
		</Tooltip>
	);
}

EditButton.propTypes = {
	app: PropTypes.object.isRequired
};
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ChannelList from './pages/ChannelList';
import './style.scss';

export default function ChannelRoutes() {
	return (
		<Routes>
			<Route index element={<ChannelList/>}/>
		</Routes>
	)
}
import React, {useState} from 'react';
import authService from '../auth/AuthService';
import {Box} from '@mui/material';
import Welcome from './boxes/Welcome';
import Notification from '../common/components/Notification';

import './style.scss';

export default function Dashboard() {
	const [error, setError] = useState(null);
	const user = authService.getCurrentUser();
	
	return (
		<Box className={'dashboard'}>
			<Welcome user={user}/>
			<Notification severity={'error'} message={error} onClear={() => setError(null)}/>
		</Box>
	)
}

import React from 'react';
import SettingsBox from '../../common/components/settings/SettingsBox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Box} from '@mui/material';
import TextField from '@mui/material/TextField';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';

export default function WelcomeBox(props) {
	
	const model = props.model;
	const onChange = props.onChange;
	
	return (
		<SettingsBox title={'Welcome Screen'}>
			<Box mb={3}>
				<FormControlLabel
					control={<Switch color='primary' checked={model.screens.welcome.enabled} onChange={e => onChange('screens.welcome.enabled', e.target.checked)}/>}
					label='Enable Welcome Screen'
					labelPlacement='end'
				/>
			</Box>
			
			{model.screens.welcome.enabled && <>
				<SettingsFormControl>
					<TextField
						variant='outlined'
						type='number'
						InputProps={{
							inputProps: {
								min: 10
							}
						}}
						label='Duration'
						helperText='Time in seconds'
						value={model.screens.welcome.duration}
						onChange={e => onChange('screens.welcome.duration', e.target.value)}
					/>
				</SettingsFormControl>
			</>}
		</SettingsBox>
	)
}
import React from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

export default function PagePreloader(props) {
	return <>
		{props.error
			? <Alert severity={'error'}>{props.error}</Alert>
			: <Backdrop open={true}>
				<CircularProgress color='inherit'/>
			</Backdrop>
		}
	</>
}

PagePreloader.propTypes = {
	error: PropTypes.string
}
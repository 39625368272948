import React, {useCallback, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';

let searchTimeout;

export default function QuestionPicker({label = 'Select a question', quizId, onChange, onError}) {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState(null);
	
	const [loading, setLoading] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	
	const onSelect = useCallback(selected => {
		setSelected(selected);
		onChange && onChange(selected);
	}, [onChange]);
	
	useEffect(() => {
		let active = true;
		clearTimeout(searchTimeout);
		
		searchTimeout = setTimeout((async () => {
			
			if (selected && selected.text === searchKey) {
				return;
			}
			
			try {
				setLoading(true);
				const searchParams = {key: searchKey, fields: ['options', 'mediaQuestion', 'mediaAnswer']};
				const response = await api.get(`/apps/quiz/${quizId}/question`, searchParams);
				
				if (active) {
					const items = response.data;
					setOptions(items);
					if (!open && !selected) {
						if (items && items.length) {
							onSelect(items[0]);
						}
					}
				}
			} catch (e) {
				if (active) {
					onError && onError(e);
				}
			} finally {
				if (active) {
					setLoading(false);
				}
			}
		}), 500);
		
		return () => {
			active = false;
		};
	}, [searchKey, open, onSelect, onChange, onError, quizId, selected]);
	
	return (
		<Autocomplete
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			onChange={(event, newValue) => {
				if (typeof newValue === 'object') {
					onSelect(newValue);
				}
			}}
			onInputChange={(event, newInputValue) => setSearchKey(newInputValue)}
			autoComplete
			freeSolo={true}
			filterOptions={(x) => x}
			value={selected}
			isOptionEqualToValue={(option, value) => value && option && option._id === value._id}
			getOptionLabel={(option) => option.text || ''}
			options={options}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					variant='outlined'
				/>
			)}
		/>
	);
}

QuestionPicker.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	onError: PropTypes.func,
	quizId: PropTypes.string.isRequired
}

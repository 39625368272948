import React from 'react';
import {Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import HeightIcon from '@mui/icons-material/Height';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

export default function QuestionPreview(props) {
	return (
		<Paper className={'poll-question-preview'}>
			<div className={`drag`} title={'Drag to change the order'} ref={props.dragRef} {...props.dragListeners}>
				<HeightIcon color={'primary'}/>
			</div>
			<div className={'number'}>
				#{props.position + 1}
			</div>
			<Typography className={'question-text'}>{props.question.text}</Typography>
			
			<IconButton
				aria-label='move up'
				onClick={() => props.onMoveUp()}
				title={'Move Up'}
				disabled={!props.canMoveUp}
				size='large'>
				<ArrowUpwardIcon/>
			</IconButton>
			
			<IconButton
				aria-label='move down'
				onClick={() => props.onMoveDown()}
				title={'Move Down'}
				disabled={!props.canMoveDown}
				size='large'>
				<ArrowDownwardIcon/>
			</IconButton>
			
			<IconButton
				aria-label='edit'
				color='primary'
				onClick={() => props.onEdit()}
				title={'Edit'}
				size='large'>
				<EditIcon/>
			</IconButton>
			<IconButton
				aria-label='delete'
				className={'remove'}
				color='secondary'
				onClick={() => props.onRemove()}
				title={'Remove'}
				size='large'>
				<DeleteIcon/>
			</IconButton>
		</Paper>
	);
}

QuestionPreview.propTypes = {
	question: PropTypes.object.isRequired,
	position: PropTypes.number.isRequired,
	onEdit: PropTypes.func.isRequired,
	onMoveUp: PropTypes.func.isRequired,
	onMoveDown: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
}
export function capitalize(s) {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}

function escapeRegExp(text) {
	return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function plural(str, num) {
	return `${num} ${str}${(num > 1 ? 's' : '')}`;
}

export function trim(str, char) {
	char = escapeRegExp(char);
	const reg = new RegExp(`^${char}+|${char}+$`, 'g');
	return str.replace(reg, '');
}


import React from 'react';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';

export default function MainContainer({maxWidth = 'lg', ...props}) {
	return <Container className={props.className} style={{marginTop: '20px', padding: 0}} maxWidth={maxWidth}>
		{props.children}
	</Container>
}

MainContainer.propTypes = {
	className: PropTypes.string,
	maxWidth: PropTypes.string
}

import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import AjaxButton from '../../common/buttons/AjaxButton';

export default function RebootButton({title = 'Reboot Streaming Server', disabled = false, ...props}) {
	return <AjaxButton
		ariaLabel={'reboot'}
		exec={async () => {
			await api.post(`/apps/broadcast/${props.appId}/reload-streamer`);
			await new Promise(r => setTimeout(r, 5000));
		}}
		icon={RestartAltIcon}
		color={'secondary'}
		title={title}
		disabled={disabled}
		{...props}
	/>
}

RebootButton.propTypes = {
	title: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onError: PropTypes.func
}

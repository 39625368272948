import api from '../common/ApiRequest';

/**
 * Check if user session exists in the local storage.
 */
let user = null;
try {
	setUser(JSON.parse(localStorage.getItem('user')));
} catch (e) {

}

/**
 * Use middleware to see if user session has been expired on the server.
 */
api.use(function (response, next) {
	if (user && [401].includes(response.status)) {
		logout();
		window.location.reload(true);
	} else {
		return next(response);
	}
});

/**
 * @param email
 * @param password
 * @returns {Promise<Object>}
 */
async function authenticate(email, password) {
	const user = await api.post('/auth/signin', {email, password});
	setUser(user);
	return user;
}

/**
 * @returns {string}
 */
function getCurrentUser() {
	return user;
}

/**
 */
function logout() {
	user = null;
	localStorage.removeItem('user');
}

/**
 * @param email
 * @param hash
 * @param password
 * @returns {Promise<Response>}
 */
async function resetPassword(email, hash, password) {
	return api.post('/auth/reset-pass', {email, hash, password});
}

/**
 * @param email
 * @returns {Promise<Object>}
 */
async function sendPasswordResetLink(email) {
	return api.post('/auth/send-pass-link', {email});
}

/**
 * Set user data.
 *
 * @param userData
 */
function setUser(userData) {
	if (userData) {
		user = userData;
		localStorage.setItem('user', JSON.stringify(user));
		api.setToken(user.token);
	}
}

/**
 * @returns {Promise<Object>}
 */
async function verifySession() {
	const user = await api.get('/auth/me');
	setUser(user);
	return user;
}

const moduleExport = {
	authenticate: authenticate,
	getCurrentUser: getCurrentUser,
	logout: logout,
	resetPassword: resetPassword,
	sendPasswordResetLink: sendPasswordResetLink,
	setUser: setUser,
	verifySession: verifySession
};

export default moduleExport;
import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

export default function SettingsBox({className = '', css = '', ...props}) {
	return (
		<Paper className={`app-settings-box ${className}`} css={css}>
			<Container>
				<div className={'box-content'}>
					<Typography component='h1' variant='h5' className={'box-title'}>
						{props.title}
					</Typography>
					
					{props.children}
				</div>
			</Container>
		</Paper>
	);
}

SettingsBox.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	css: PropTypes.string
}

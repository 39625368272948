import React from 'react';
import StopButton from './StopButton';
import StartButton from './StartButton';
import PropTypes from 'prop-types';

export default function StartStopButton(props) {
	
	const app = props.app;
	
	return ['starting', 'running', 'paused'].includes(app.status)
		? <StopButton
			appId={app._id}
			onChange={() => props.onChange('stop')}
			onError={props.onError}
		/>
		: <StartButton
			title={'Start'}
			disabled={app.checkStatus.code === 'error'}
			appId={app._id}
			onChange={() => props.onChange('start')}
			onError={props.onError}
		/>
}

StartStopButton.propTypes = {
	app: PropTypes.object.isRequired,
	onError: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
}
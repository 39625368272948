/**
 * @param token
 * @returns {{redirect: string, headers: {"X-AUTH-TOKEN": *}, cache: string, credentials: string}}
 */
function getFetchParams(token) {
	return {
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			'X-AUTH-TOKEN': token
		},
		redirect: 'follow'
	};
}

/**
 * @param url
 * @param params
 * @param middleware
 * @returns {Promise<*>}
 */
function handleFetch(url, params, middleware) {
	let originalResponse;
	
	return fetch(url, params)
		.then(response => {
			originalResponse = response;
			return middleware(response);
		})
		.then(response => {
			if (response) {
				return response.json();
			}
			return {};
		})
		.catch(e => {
			return {};
		})
		.then(data => {
			if (data && data.error) {
				throw data.error;
			} else if (!originalResponse.ok) {
				throw originalResponse.statusText;
			}
			return data;
		})
		.catch(e => {
			throw e.toString()
		});
}

class ApiRequest {
	
	baseUrl;
	token;
	
	/**
	 * @param url
	 */
	constructor(url) {
		this.baseUrl = url;
	}
	
	/**
	 * DELETE request.
	 *
	 * @param endpoint
	 * @return {Promise<Response>}
	 */
	delete(endpoint) {
		return this.fetch('DELETE', endpoint);
	}
	
	/**
	 * Fetch data from the remote resource.
	 *
	 * @param endpoint
	 * @return {Promise<any | never>}
	 */
	download(endpoint) {
		const url = this.baseUrl + endpoint;
		const params = getFetchParams(this.token);
		params.method = 'GET';
		return fetch(url, params)
			.then(response => {
				if (!response.ok) {
					throw response.statusText;
				}
				return response.text();
			})
			.catch(e => {
				throw e.toString()
			});
	}
	
	/**
	 * Fetch data from the remote resource.
	 *
	 * @param method
	 * @param endpoint
	 * @param data
	 * @return {Promise<any | never>}
	 */
	fetch(method, endpoint, data = null) {
		const url = this.baseUrl + endpoint;
		const params = getFetchParams(this.token);
		params.method = method;
		params.headers['Content-Type'] = 'application/json';
		
		if (data) {
			params.body = JSON.stringify(data);
		}
		
		return handleFetch(url, params, this.handleResponse.bind(this));
	}
	
	/**
	 * GET request.
	 *
	 * @param endpoint
	 * @param data
	 * @return {Promise<Response>}
	 */
	get(endpoint, data = null) {
		if (data) {
			endpoint += '?' + this.serialize(data);
		}
		
		return this.fetch('GET', endpoint);
	}
	
	/**
	 * POST request.
	 *
	 * @param endpoint
	 * @param data
	 * @return {Promise<Response>}
	 */
	post(endpoint, data) {
		return this.fetch('POST', endpoint, data);
	}
	
	/**
	 * PUT request.
	 *
	 * @param endpoint
	 * @param data
	 * @return {Promise<Response>}
	 */
	put(endpoint, data) {
		return this.fetch('PUT', endpoint, data);
	}
	
	/**
	 * Serialize object.
	 *
	 * @param obj
	 * @param prefix
	 * @return {string}
	 */
	serialize(obj, prefix = '') {
		let str = [], p;
		for (p in obj) {
			if (obj.hasOwnProperty(p)) {
				let k = prefix ? prefix + '[' + p + ']' : p,
					v = obj[p];
				str.push((v !== null && typeof v === 'object') ?
					this.serialize(v, k) :
					encodeURIComponent(k) + '=' + encodeURIComponent(v));
			}
		}
		return str.join('&');
	}
	
	/**
	 * @param token
	 */
	setToken(token) {
		this.token = token;
	}
	
	/**
	 * Fetch data from the remote resource.
	 *
	 * @param method
	 * @param endpoint
	 * @param data
	 * @return {Promise<any | never>}
	 */
	upload(endpoint, file, data) {
		const url = this.baseUrl + endpoint;
		const params = getFetchParams(this.token);
		params.method = 'POST';
		
		const formData = new FormData();
		formData.append('file', file);
		if (data) {
			for (let key in data) {
				formData.append(key, data[key]);
			}
		}
		params.body = formData;
		
		return handleFetch(url, params, this.handleResponse.bind(this));
	}
	
	/**
	 * Middleware pattern implementation.
	 *
	 * @param fn
	 */
	use(fn) {
		const prev = this.handleResponse;
		this.handleResponse = function (response) {
			return fn(response, prev);
		}
	}
	
	/**
	 * @param response
	 * @returns {*}
	 */
	handleResponse = (response) => {
		return response;
	}
}

const api = new ApiRequest(process.env.REACT_APP_API_SERVER);
export default api;
import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import authService from '../AuthService';

export default function LogOut() {
	const [done, setDone] = useState(false);
	
	useEffect(() => {
		authService.logout();
		setDone(true);
	},[])
	
	return (
		<>
			{done && <Navigate to={`/auth/signin`}/>}
		</>
	);
}
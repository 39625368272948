import {InputAdornment, Paper, TextField} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React, {useState} from 'react';
import PropTypes from 'prop-types';

export default function SearchBar({placeholder = 'Search...', onSubmit}) {
	const [key, setKey] = useState(null);
	
	const onClear = () => {
		setKey('');
		onSubmit && onSubmit('');
	}
	
	const onKeyPress = e => {
		if (e.key === 'Enter' || e.which === 13) {
			onSubmit && onSubmit(key);
		}
	}
	
	return (
		<Paper className={'search-bar'}>
			<TextField
				value={key || ''}
				size='small'
				className={'search-field'}
				placeholder={placeholder}
				onChange={e => setKey(e.target.value || '')}
				onKeyUp={onKeyPress}
				slotProps={{
					input: {
						endAdornment: <InputAdornment position={'end'}>
							<IconButton onClick={onClear} edge={'end'}>
								<HighlightOffIcon/>
							</IconButton>
						</InputAdornment>
					}
				}}
			/>
		</Paper>
	);
}

SearchBar.propTypes = {
	onSubmit: PropTypes.func,
	placeholder: PropTypes.string
}

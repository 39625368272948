import objectPath from 'object-path';

export function sanitize(data) {
	const newData = JSON.parse(JSON.stringify(data));
	newData.questions.forEach(q => {
		replaceImageWithId(q, 'background');
	});
	
	// Sanitize livestreams.
	if (newData.livestreams) {
		newData.livestreams = newData.livestreams.map(livestream => {
			if (typeof livestream.channel === 'object') {
				livestream.channel = livestream.channel._id;
			}
			return livestream;
		});
	}
	
	return newData;
}

function replaceImageWithId(obj, path) {
	const img = objectPath.get(obj, path);
	if (img && img._id) {
		objectPath.set(obj, path, img._id);
	} else {
		objectPath.set(obj, path, null);
	}
}

import React, {useState} from 'react';
import objectPath from 'object-path';
import MainContainer from '../../common/components/MainContainer';
import SettingsTab from './SettingsTab';
import AppBar from '@mui/material/AppBar';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import BroadcastTabs from './BroadcastTabs';

export default function BroadcastEditor({saving = false, ...props}) {
	const navigate = useNavigate();
	const [model, setModel] = useState(props.model);
	
	/**
	 * @param path
	 */
	const onTabClick = path => {
		model._id && navigate(`/broadcast/${model._id}/${path}`);
	}
	
	/**
	 * @param prop
	 * @param value
	 */
	const modelChanged = (prop, value) => {
		
		const newModel = {...model};
		objectPath.set(newModel, prop, value);
		
		if (prop === 'channelId') {
			newModel.livestream = null;
		}
		
		setModel(newModel);
	};
	
	const onSubmit = () => {
		if (!model.name || !model.name.trim()) {
			return props.onError('Name is required');
		} else if (props.onSubmit) {
			props.onSubmit(model);
		}
	};
	
	return (<MainContainer className={'app-editor'}>
		<AppBar position={'sticky'} color={'default'}>
			<BroadcastTabs selected={''} onClick={onTabClick}/>
		</AppBar>
		
		<SettingsTab
			channels={props.channels}
			model={model}
			onChange={modelChanged}
			onError={props.onError}
			onSubmit={onSubmit}
			saving={saving}
		/>
	
	</MainContainer>)
}

BroadcastEditor.propTypes = {
	model: PropTypes.object.isRequired,
	onError: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	saving: PropTypes.bool
};

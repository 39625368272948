import React from 'react';
import {FormControl} from '@mui/material';
import PropTypes from 'prop-types';

export default function SettingsFormControl({className = '', ...props}) {
	return <FormControl
		fullWidth
		{...props}
		className={`${className} settings-form-control`}>{props.children}</FormControl>
}

SettingsFormControl.propTypes = {
	className: PropTypes.string
}

import React from 'react';
import {Toolbar} from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

export default function ActionBar({type = 'button', saveDisabled = false, saving = false, showSaveBtn = true, ...props}) {
	return (
		<Paper className={'quiz-action-bar'}>
			<Toolbar>
				{saving && <CircularProgress color={'primary'} size={18} className={'loader'}/>}
				{props.children}
				{showSaveBtn && <Button
					variant={'contained'}
					color={'primary'}
					disabled={saving || saveDisabled}
					type={type}
					onClick={props.onSubmit}
				>
					Save Changes
				</Button>}
			</Toolbar>
		</Paper>
	)
}

ActionBar.propTypes = {
	type: PropTypes.string,
	saveDisabled: PropTypes.bool,
	saving: PropTypes.bool,
	showSaveBtn: PropTypes.bool,
	onSubmit: PropTypes.func
}

import React, {useState} from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField';

import {converters, DEFAULT_CONVERTER} from './transformers'
import PickerDialog from './PickerDialog'

const ColorPicker = ({
	                     // ColorPicker
	                     onChange,
	                     onOpen,
	                     convert = DEFAULT_CONVERTER,
	                     
	                     // Text field
	                     name,
	                     id,
	                     hintText,
	                     placeholder,
	                     floatingLabelText,
	                     label,
	                     TextFieldProps,
	                     value = '#fff',
	                     
	                     ...custom
                     }) => {
	const showPicker = custom.open;
	const [internalValue, setValue] = useState(value);
	
	const onKeyPress = e => {
		if (e.key === 'Enter') {
			onChange(value);
			custom.onClose();
			e.preventDefault();
		}
	}
	
	return (
		<div onKeyPress={onKeyPress}>
			<TextField
				name={name}
				id={id}
				label={floatingLabelText || label}
				placeholder={hintText || placeholder}
				onClick={() => onOpen && onOpen()}
				onChange={e => {
					setValue(e.target.value)
					onChange(e.target.value)
				}}
				InputProps={{style: {color: value === undefined ? internalValue : value}}}
				{...TextFieldProps}
				{...custom}
			/>
			{showPicker && (
				<PickerDialog
					value={value === undefined ? internalValue : value}
					onClick={() => {
						onChange(value);
						custom.onClose();
					}}
					onChange={c => {
						const newValue = converters[convert](c)
						setValue(() => newValue)
						onChange(newValue)
					}}
				/>
			)}
		</div>
	)
}

ColorPicker.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	onClose: PropTypes.func,
	onOpen: PropTypes.func,
	convert: PropTypes.oneOf(Object.keys(converters)),
	name: PropTypes.string,
	id: PropTypes.string,
	hintText: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	floatingLabelText: PropTypes.string,
	TextFieldProps: PropTypes.shape(TextField.propTypes),
	open: PropTypes.bool
}

export default ColorPicker

import React from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';

/** @jsxImportSource @emotion/react */

export default function FailScreen(props) {
	const model = props.model;
	const screen = model.screens.scores;
	
	return (
		<Screen
			className={`scores`}
			bgColor={screen.fail.bgColor}
			mediaBg={screen.fail.background}
			css={{
				'& .screen-title': {
					fontSize: `${screen.screenTitle.fontSize}px`,
					color: screen.screenTitle.color,
					backgroundColor: screen.screenTitle.bgColor
				},
				'& .fail-text': {
					fontSize: `${screen.fail.fontSize}px`,
					color: screen.fail.color,
					backgroundColor: screen.fail.textBgColor
				}
			}}
		>
			<div className={`screen-title`}>{screen.title}</div>
			<div className={`screen-content`}>
				{screen.failText && (
					<div className={`fail-text ${screen.fail.position}`}>{screen.failText}</div>
				)}
			</div>
		</Screen>
	)
}

FailScreen.propTypes = {
	model: PropTypes.object.isRequired
};
